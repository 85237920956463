<template>
  <div>

    <label>Promo Item</label>
    
    <promo-item-selection></promo-item-selection>

    <promo-period-selector
        label="Promotion Period"
        placeholder="Start/End control period"
        help-text="The Start/End dates for the control period"
        v-model="promotionDateRange">
    </promo-period-selector>
    
    
    <div class="uk-position-bottom-center uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top">
      <button class="uk-button uk-button-primary" @click="goToPromotionDetail">Load Promotion Data</button>
    </div>
    
  </div>
</template>
<script>

import PromoItemSelection from "@/Components/Homepage/Components/PromoItemSelection.vue";
import PromoPeriodSelector from "@/Components/Homepage/Components/PromoPeriodSelector.vue";

export default {
  name: "PromoSelectionCard",
  components: {
    PromoItemSelection,
    PromoPeriodSelector
  },
  
  data () {
    return {
      itemName: '',
      promotionDateRange: {
        start: '',
        end: ''
      },
      participatingStores: []
    };
  },

  created () {
    this.eventHub.$on('promoItemSelection', this.savePromoItem);
  },

  beforeDestroy() {
    this.eventHub.$off('promoItemSelection');
  },


  mounted () {
  },
  
  methods: {

    savePromoItem(item) {
      this.selectedPromoItem = item;
    },
    
    goToPromotionDetail() {
      
      let promoObject = {
        itemName: this.selectedPromoItem,
        endDate: this.promotionDateRange.end,
        startDate: this.promotionDateRange.start
      }

      this.eventHub.$emit("showPromoDetail", promoObject);
      
    }
    
    
  }
}
</script>

<style scoped>

</style>