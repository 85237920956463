<template>
  <div class="uk-flex uk-flex-column">

    <div class="uk-text-bold">{{ storeData.groupName }} (Rank: {{ storeData.rank }})</div>

    <div v-if="storeData !== null"
         :class="{'uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap': true, 'uk-grid-small': windowWidth < 1600, 'uk-grid-medium': windowWidth >= 1600}" data-uk-grid>
      <div>
        <span class="uk-text-small"><span class="fa fa-money uk-margin-small-right uk-text-primary"></span>Comp Sales</span>
        <div>
          <h1 :class="getTextColor(storeData.compPct)">{{ storeData.compPct | formatPercentageChannel }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span class="uk-text-primary">{{ storeData.compSales | formatMoneyNoCents }}</span> Comp Sales
        </div>
      </div>

      <div>
                <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
                    class="fa fa-user-circle" aria-hidden="true"/></span>Comp Traffic</span>
        <div>
          <h1 :class="getTextColor(storeData.trafficCompPct)">{{ storeData.trafficCompPct | formatPercentageChannel }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span class="uk-text-primary">{{ storeData.compTraffic | formatShortNumber }} </span> Guests
        </div>
      </div>

      <div>
                <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
                    class="fa fa-credit-card"
                    aria-hidden="true"></i></span>Average Check</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span class="small-font">$</span>{{ storeData.averageCheck | formatAverageCheck }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(storeData.averageCheckCompPct)"
                :data-uk-icon="getIcon(storeData.averageCheckCompPct)">{{ storeData.averageCheckCompPct.toFixed(1)  | formatPercentageString }}</span> Chg
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";

export default {
  name: "TopBottomStoresMetric",
  components: {
    SmallSlimsLoader
  },

  data () {
    return {
      windowWidth: window.innerWidth,
    }
  },

  props: {
    storeData: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    window.addEventListener("resize", this.windowResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },

  methods: {
    getTextColor(data) {
      if (data > 0)
        return "uk-heading-primary uk-margin-remove uk-text-success"
      else
        return "uk-heading-primary uk-margin-remove uk-text-danger"
    },

    windowResize ()
    {
      this.windowWidth = window.innerWidth;
    },

    getIcon(data) {
      if (data > 0)
        return "icon: triangle-up"
      else
        return "icon: triangle-down"
    },
  },


  filters: {


    formatPercentageChannel (number) {
      if (number === null)
        return 'Null';

      number = number.toFixed(1);

      return number;
    },

    formatShortMoney(num) {
      if (num > 1000 && num < 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' k' : Math.sign(num) * Math.abs(num);

      if (num > 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + ' m' : Math.sign(num) * Math.abs(num);

      else return num;
    },
  }
}
</script>

<style scoped>
.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

div {
  white-space: pre;
}

.small-font {
  font-size: 20px;
}

</style>