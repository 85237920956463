<template>
  <div v-if="columnData !== null && dataSource !== null">
    <DxDataGrid
        v-if="(!isCorporate && fmmFilter.length !== 0 && fadFilter.length !== 0) || (isCorporate) "
        :show-borders="true"
        :data-source="dataSource"
        height="600px"
        :customize-columns="customizeColumns"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing($event)">


    <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxExport
          :enabled="true"
      />

      <DxScrolling mode="infinite"/>

      <DxColumn
          :visible-index="0"
          data-field="groupName"
          caption="Group Name"/>

      <DxColumn
          :visible-index="1"
          v-if="colShow(1)"
          data-field="pctChangePeriod1"
          :caption="colName(1)"
          cell-template="Period1"/>

      <DxColumn
          :visible-index="2"
          v-if="colShow(2)"
          data-field="pctChangePeriod2"
          :caption="colName(2)"
          cell-template="Period2"/>

      <DxColumn
          :visible-index="3"
          v-if="colShow(3)"
          data-field="pctChangePeriod3"
          :caption="colName(3)"
          cell-template="Period3"/>

      <DxColumn
          :visible-index="4"
          v-if="colShow(4)"
          data-field="pctChangePeriod4"
          :caption="colName(4)"
          cell-template="Period4"/>

      <DxColumn
          :visible-index="5"
          v-if="colShow(5)"
          data-field="pctChangePeriod5"
          :caption="colName(5)"
          cell-template="Period5"/>

      <DxColumn
          :visible-index="6"
          v-if="colShow(6)"
          data-field="pctChangePeriod6"
          :caption="colName(6)"
          cell-template="Period6"/>

      <DxColumn
          :visible-index="7"
          v-if="colShow(7)"
          data-field="pctChangePeriod7"
          :caption="colName(7)"
          cell-template="Period7"/>

      <DxColumn
          :visible-index="8"
          v-if="colShow(8)"
          data-field="pctChangePeriod8"
          :caption="colName(8)"
          cell-template="Period8"/>

      <DxColumn
          :visible-index="9"
          v-if="colShow(9)"
          :caption="colName(9)"
          data-field="pctChangePeriod9"
          cell-template="Period9"/>

      <DxColumn
          :visible-index="10"
          v-if="colShow(10)"
          :caption="colName(10)"
          data-field="pctChangePeriod10"
          cell-template="Period10"/>

      <DxColumn
          :visible-index="11"
          v-if="colShow(11)"
          :caption="colName(11)"
          data-field="pctChangePeriod11"
          cell-template="Period11"/>

      <DxColumn
          :visible-index="12"
          v-if="colShow(12)"
          :caption="colName(12)"
          data-field="pctChangePeriod12"
          cell-template="Period12"/>

      <DxColumn
          data-field="pctChangeTotal"
          caption="Total"
          cell-template="Total"/>

      <template #Period1="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod1 < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
              data.data.changePeriod1 | formatMoney
            }} </span>
          <span v-if="data.data.changePeriod1 >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
              data.data.changePeriod1 | formatMoney
            }} </span>
        </div>
      </template>

      <template #Period2="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod2 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod2 | formatMoney }} </span>
          <span v-if="data.data.changePeriod2 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod2 | formatMoney }} </span>
        </div>
      </template>

      <template #Period3="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod3 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod3 | formatMoney }} </span>
          <span v-if="data.data.changePeriod3 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod3 | formatMoney }} </span>
        </div>
      </template>

      <template #Period4="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod4 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod4 | formatMoney }} </span>
          <span v-if="data.data.changePeriod4 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod4 | formatMoney }} </span>
        </div>
      </template>


      <template #Period5="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod5 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod5 | formatMoney }} </span>
          <span v-if="data.data.changePeriod5 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod5 | formatMoney }} </span>
        </div>
      </template>

      <template #Period6="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod6 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod6 | formatMoney }} </span>
          <span v-if="data.data.changePeriod6 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod6 | formatMoney }} </span>
        </div>
      </template>

      <template #Period7="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod7 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod7 | formatMoney }} </span>
          <span v-if="data.data.changePeriod7 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod7 | formatMoney }} </span>
        </div>
      </template>

      <template #Period8="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod8 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod8 | formatMoney }} </span>
          <span v-if="data.data.changePeriod8 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod8 | formatMoney }} </span>
        </div>
      </template>

      <template #Period9="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod9 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod9 | formatMoney }} </span>
          <span v-if="data.data.changePeriod9 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod9 | formatMoney }} </span>
        </div>
      </template>

      <template #Period10="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod10 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod10 | formatMoney }} </span>
          <span v-if="data.data.changePeriod10 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod10 | formatMoney }} </span>
        </div>
      </template>

      <template #Period11="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod11 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod11 | formatMoney }} </span>
          <span v-if="data.data.changePeriod11 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod11 | formatMoney }} </span>
        </div>
      </template>

      <template #Period12="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changePeriod12 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod12 | formatMoney }} </span>
          <span v-if="data.data.changePeriod12 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod12 | formatMoney }} </span>
        </div>
      </template>

      <template #Total="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changeTotal < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
          <span v-if="data.data.changeTotal >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
        </div>
      </template>
      
      <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
      />

      <template #masterDetailTemplate="{ data }">

              <ytd-level2 
                  class="div-background"
                  :template-data="data"
                               :is-corporate="isCorporate"
              />

      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';


import {createStore} from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from 'file-saver';
import YtdLevel2 from "@/Components/DetailPages/Parts/Ytd/Components/YtdLevel2.vue";

export default {
  name: "PeriodLevel1",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    YtdLevel2,
    DxExport

  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      columnData: null,
      fmmFilter: [],
      fadFilter: [],
      currentFmm: "All",
      currentFad: "All",
    }
  },

  mounted() {

    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    if (!this.isCorporate) {
      axios.get(`${url}/api/slims-report/contribution-comp-filter`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          type: 'FMM',
          clientId: clientId
        }
      }).then(response => {
        for (let x = 0; x < response.data.length; x++) {
          this.fmmFilter.push(response.data[x])
        }
        axios.get(`${url}/api/slims-report/contribution-comp-filter`, {
          headers: {Authorization: `Bearer ${token}`},
          params: {
            type: 'FAD',
            clientId: clientId
          }
        }).then(response => {
          for (let x = 0; x < response.data.length; x++) {
            this.fadFilter.push(response.data[x])
          }
        });
      });
    }
    
    this.loadData();
  },

  props: {
    isCorporate: Boolean,
  },

  filters: {
    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toFixed(1);
      number = number.toString() + "%";

      return number;
    },


    formatMoney(number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  methods: {

    fmmChanged(e)
    {
      this.currentFmm = e.value;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/comp-sales/ytd-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

    fadChanged(e)
    {
      this.currentFad = e.value;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/comp-sales/ytd-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
            location: 'before',
          }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a FMM Rollup",
              items: this.fmmFilter,
              value: this.fmmFilter[0].value,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: this.fmmChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a FAD Rollup",
              items: this.fadFilter,
              value: this.fadFilter[0].value,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: this.fadChanged.bind(this)
            }
          },
      );

    },

    customizeColumns(columns) {
      
      
    },

    colName(index) {
      if (this.columnData.length >= index)
        return "Period " + this.columnData[index - 1].period;
      else
        return "";
    },

    colShow(index) {
      if (this.columnData.length >= index)
        return true;
      else
        return false;
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Franchise Groups Comp % YTD');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 2; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: cell.value + "%"}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              "Franchise Groups Comp % YTD.xlsx");
        });

      });
    },

    loadData() {

      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/comp-sales/ytd-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            clientId: clientId
          };
        }
      });

      let me = this;
      const token = this.getTokenSilently();
      axios.get(`${url}/api/slims-report/get-ytd-information`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {clientId: clientId}
      }).then(response => {
        me.columnData = response.data;
       
      }).finally(function () {
        me.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

.top-metric {
  font-size: large;
}

.div-background {
  border: solid 1px black;
}

</style>