<template>
  <div>
    <div class="center-inside">
      <div class="uk-width-1-1@l center-inside">
        <div class="uk-card uk-card-default uk-card-small">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Group Overview</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
                <button class="uk-button uk-button-primary uk-text-middle comp-button">
                  <span v-show="!showCompSalesOverview" @click="switchOverview('comp')" class="comp-button-text">Show Comp %</span>
                  <span v-show="showCompSalesOverview" @click="switchOverview('sales')"
                        class="comp-button-text">Show $</span>
                </button>
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <group-overview-grid v-if="!showCompSalesOverview"></group-overview-grid>
              <group-overview-grid-comp v-if="showCompSalesOverview"></group-overview-grid-comp>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import GroupOverviewGridComp from "@/Components/DetailPages/Parts/StoreOverview/Components/GroupOverviewGridComp.vue";
import GroupOverviewGrid from "@/Components/DetailPages/Parts/StoreOverview/Components/GroupOverviewGrid.vue";

export default {
  name: "StoreOverviewApp",
  components: {GroupOverviewGrid, GroupOverviewGridComp},

  data() {
    return {
      showCompSalesOverview: false
    }
  },

  methods: {
    switchOverview(value) {
      if (value === 'sales')
        this.showCompSalesOverview = false;
      if (value === 'comp')
        this.showCompSalesOverview = true;
    }
  }
}
</script>

<style scoped>
.center-inside {
  display: block;
  margin: auto;
  padding: 3px;
}

.comp-button {
  height: 30px;
  display: inline-flex;
  align-items: center;
}
</style>