const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('access_token');
const apiUrl = 'https://api-legacy-csharp.engine.net'
// const apiUrl = 'https://localhost:7020'
const clientId = 'f93d62c9-a483-4a4b-9960-ad44e5039fc8'

export const AuthPlugin = {
    install(Vue, options) {
        Vue.prototype.getTokenSilently = () => {
            return token;
        }
        Vue.prototype.getApiUrl = () => {
            return apiUrl;
        }
        Vue.prototype.getClientId = () => {
            return clientId;
        }
    },
}
