<template>
  <DxChart
      class="chart-height"
      v-if="!isLoading"
      :data-source="dataSource"
  >

    <DxLegend
        position="outside"
        horizontal-alignment="center"
        vertical-alignment="bottom"
    />

    <DxTooltip
        :enabled="true"
        :customize-tooltip="customizeTooltip"
    >

      <DxFormat
          type="fixedPoint"
          :precision="1"
      />

    </DxTooltip>

    <DxCommonSeriesSettings
        argument-field="index"
        type="line">

    </DxCommonSeriesSettings>

    <DxSeries
        argument-field="index"
        value-field="currentGoi"
        name="This Promotion GOI"
        type="line"
        :width="3"
        color="#1bb2f5"
        :showInLegend="true">
    </DxSeries>

    <DxSeries
        argument-field="index"
        value-field="baseGoi"
        name="Last Promotion GOI"
        type="line"
        color="#f5564a"
        :width="3"
        :showInLegend="true">

    </DxSeries>

    <DxArgumentAxis
        title="Day Of Promotion">
    </DxArgumentAxis>

    <DxValueAxis
        title="GOI %"
    />

    <DxAnnotation
        :argument="1"
        type="text"
        text="Promotion Started">
    </DxAnnotation>

  </DxChart>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxTooltip,
  DxArgumentAxis,
  DxValueAxis,
  DxConstantLine,
  DxAnnotation, DxFormat, DxAggregation, DxCommonSeriesSettings, DxLegend

} from 'devextreme-vue/chart';

import axios from "axios";

export default {
  name: "PromotionGraph",
  components: {
    DxChart,
    DxSeries,
    DxTooltip,
    DxArgumentAxis,
    DxValueAxis,
    DxConstantLine,
    DxAnnotation, 
    DxFormat,
    DxAggregation, 
    DxCommonSeriesSettings, 
    DxLegend
  },
  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      data: null,
      dataSource: null,
      isLoading: false,
      startPromoDate: new Date(this.promotionData.startDate),
      endPromoDate: new Date(this.promotionData.endDate)
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {

    vueDate(date) {
      return new Date(date);
    },


    customizeTooltip(pointsInfo) {
      let number = pointsInfo.value.toFixed(1);
      number = number.toString() + '%';
      return {
        text: `GOI: ${number}\nDay Of Promotion: ${pointsInfo.argumentText}`,
      };
    },

    loadData() {
      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      const token = this.getTokenSilently();
      axios.get(`${url}/api/slims-report/promotions/get-promo-detail`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          promoItem: this.promotionData.plu,
          startDate: this.promotionData.startDate,
          endDate: this.promotionData.endDate,
          clientId: clientId
        }
      }).then(response => {
        this.data = response.data;

        this.dataSource = this.data;

        this.isLoading = false;
      });

    }
  }
};
</script>

<style scoped>

.chart-height {
  height: 450px
}

</style>