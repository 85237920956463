<template>
  <div class="uk-flex uk-flex-column">

    <div class="uk-text-bold">Domestic System Total</div>

    <small-slims-loader v-if="isLoading || sysData === null"></small-slims-loader>

    <div v-if="!isLoading && sysData !== null"
         class="uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap uk-grid-medium" data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ sysData.totalYesterday | formatShortMoney }} </h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(sysData.changeYesterday)"
                :data-uk-icon="getIcon(sysData.changeYesterday)">{{ sysData.changeYesterday | formatPctChange }}</span> from LY
        </div>
      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"  class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span class="small-font">$</span>{{ sysData.totalRolling7 | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(sysData.changeRolling7)"
                :data-uk-icon="getIcon(sysData.changeRolling7)">{{ sysData.changeRolling7 | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ sysData.totalPeriod | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(sysData.changePeriod)"
                :data-uk-icon="getIcon(sysData.changePeriod)">{{ sysData.changePeriod | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar" aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined" :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]" class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ sysData.totalYtd | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(sysData.changeYtd)"
                :data-uk-icon="getIcon(sysData.changeYtd)">{{ sysData.changeYtd | formatPctChange }}</span> from LY
        </div>
      </div>
    </div>

    <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold">Domestic Franchise Stores</div>

    <small-slims-loader v-if="isLoadingFran || data === null"></small-slims-loader>

    <div v-if="!isLoadingFran && data !== null"
         class="uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap uk-grid-medium" data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalYesterday | formatShortMoney }} </h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(data.changeYesterday)"
                :data-uk-icon="getIcon(data.changeYesterday)">{{ data.changeYesterday | formatPctChange }}</span> from LY
        </div>
      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"  class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalRolling7 | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(data.changeRolling7)"
                :data-uk-icon="getIcon(data.changeRolling7)">{{ data.changeRolling7 | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalPeriod | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(data.changePeriod)"
                :data-uk-icon="getIcon(data.changePeriod)">{{ data.changePeriod | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar" aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined" :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]" class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalYtd | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(data.changeYtd)"
                :data-uk-icon="getIcon(data.changeYtd)">{{ data.changeYtd | formatPctChange }}</span> from LY
        </div>
      </div>
    </div>

    <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold">Corporate Stores</div>

    <small-slims-loader v-if="isLoadingCorp || corpData === null"></small-slims-loader>

    <div v-if="!isLoadingCorp && corpData !== null"
         class="uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap uk-grid-medium" data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalYesterday | formatShortMoney }} </h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(corpData.changeYesterday)"
                :data-uk-icon="getIcon(corpData.changeYesterday)">{{ corpData.changeYesterday | formatPctChange }}</span> from LY
        </div>
      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"  class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalRolling7 | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(corpData.changeRolling7)"
                :data-uk-icon="getIcon(corpData.changeRolling7)">{{ corpData.changeRolling7 | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalPeriod | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(corpData.changePeriod)"
                :data-uk-icon="getIcon(corpData.changePeriod)">{{ corpData.changePeriod | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar" aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined" :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]" class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalYtd | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(corpData.changeYtd)"
                :data-uk-icon="getIcon(corpData.changeYtd)">{{ corpData.changeYtd | formatPctChange }}</span> from LY
        </div>
      </div>
    </div>


    <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold">Stores Opened This Year (NRO)</div>

    <small-slims-loader v-if="isLoadingOty || nroData === null"></small-slims-loader>

    <div v-if="!isLoadingOty && nroData !== null"
         class="uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap uk-grid-medium" data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ nroData.totalYesterday | formatShortMoney }} </h1>
        </div>

      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"  class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ nroData.totalRolling7 | formatShortMoney }}</h1>
        </div>

      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ nroData.totalPeriod | formatShortMoney }}</h1>
        </div>

      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar" aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined" :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]" class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div class="clickable">
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ nroData.totalYtd | formatShortMoney }}</h1>
        </div>


      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";
import SmallLoaderComponent from "../../../Assets/js/Shared/SmallLoaderComponent.vue";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";


export default {
  name: "CompSalesCard",
  components: {
    SmallLoaderComponent,
    SmallSlimsLoader
  },

  data() {
    return {
      data: null,
      nroData: null,
      sysData: null,
      corpData: null,
      isLoading: false,
      isLoadingOty: false,
      isLoadingFran: false,
      isLoadingCorp: false
    }
  },

  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },

  methods: {
    goToDetail(metric) {
      this.eventHub.$emit("compDetailPage", metric);
    },

    getTextColor(data) {
      if (data > 0)
        return "uk-text-success"
      else
        return "uk-text-danger"
    },

    getIcon(data) {
      if (data > 0)
        return "icon: triangle-up"
      else
        return "icon: triangle-down"
    },
  },


  filters: {
    formatShortMoney(num) {
      if (num > 1000 && num < 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' k' : Math.sign(num) * Math.abs(num);

      if (num > 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + ' m' : Math.sign(num) * Math.abs(num);
    },

    formatPctChange: function (number) {
      if (number === null)
        return 'Null';

      number = number.toString();
      number += '%';

      return number;
    }
  },

  mounted() {

    this.isLoading = true;
    this.isLoadingOty = true;
    this.isLoadingFran = true;
    this.isLoadingCorp = true;

    let me = this;
    const token = this.getTokenSilently();

    //Franchise
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/overall-sales-kpi`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        filter: 'Franchise',
        clientId: clientId
      }
    })
        .then(response => {
          me.data = response.data[0];
        })
        .catch(err => {
        })
        .finally(() => {
          this.isLoadingFran = false;
        });


    //Stores Opened This Year
    axios.get(`${url}/api/slims-report/overall-sales-kpi`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        filter: 'NRO',
        clientId: clientId
      }
    })
        .then(response => {
          me.nroData = response.data[0];
        })
        .catch(err => {
        })
        .finally(() => {
          this.isLoadingOty = false;
        });

    //Corporate Stores
    axios.get(`${url}/api/slims-report/overall-sales-kpi`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        filter: 'Corporate',
        clientId: clientId
      }
    })
        .then(response => {
          me.corpData = response.data[0];
        })
        .catch(err => {
        })
        .finally(() => {
          this.isLoadingCorp = false;
        });

    //System Total
    axios.get(`${url}/api/slims-report/overall-sales-kpi`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {clientId: clientId}
    })
        .then(response => {
          me.sysData = response.data[0];
        })
        .catch(err => {
        })
        .finally(() => {
          this.isLoading = false;
        });
  }
}
</script>

<style scoped>


.small-font {
  font-size: 20px;
}

div:not(#loader) {
  white-space: pre;
}

.sys-total-label {
  padding-top: 62px;
}
</style>