<template>
  <DxChart
      v-if="dataSource != null"
      :data-source="dataSource"
      :customize-label="customizeText"
  >
    <DxCommonSeriesSettings
        argument-field="channel"
        type="bar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
    >
      <DxLabel :visible="true">
        <DxFormat
            :customize-text="customizeText"
        />
      </DxLabel>
    </DxCommonSeriesSettings>
    <DxSeries
        value-field="salesTy"
        name="Sales This Year"
    />
    <DxSeries
        value-field="salesLy"
        name="Sales Last Year"
    />
    <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
    />
    <DxExport :enabled="true"/>
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport,
} from 'devextreme-vue/chart';
import {createStore} from "devextreme-aspnet-data-nojquery";

export default {
  name: "ChannelBarChart",
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
  },

  props: {
    isCorporate: Boolean
  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      dollarFormat: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0})
    };
  },

  mounted () {
    this.loadData();
  },

  created() {
    this.eventHub.$on('channel_filter', this.loadDataWithFilters)
    this.eventHub.$on('clearFilter', this.loadData)
  },

  beforeDestroy() {
    this.eventHub.$off('channel_filter');
    this.eventHub.$off('clearFilter');
  },


  methods: {
    loadData() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/channel-bar`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
    },

    customizeText(points) {
      return "$" + this.dollarFormat.format(points.value);
    },

    loadDataWithFilters(filterObject) {
      const url = this.getApiUrl();
      filterObject.clientId = this.getClientId();
      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/channel-bar`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = filterObject;
        }
      });
    }
  }
};
</script>
<style>
#chart {
  height: 440px;
}


.height-div {
  height: 200px;
}
</style>