<template>
  <div class="uk-flex uk-flex-column">

    <div class="uk-text-bold">{{storeData.storeName}}</div>

    <div>Opened: {{storeData.dateOpened | formatDate}}</div>

    <div 
         class="uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap uk-grid-medium" data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ storeData.yesterday | formatShortMoney }} </h1>
        </div>
        <!--        <div class="uk-text-small small-text-css">-->
        <!--          <span :class="getTextColor(sysData.changePeriod)"-->
        <!--                :data-uk-icon="getIcon(sysData.changePeriod)">{{ sysData.changePeriod | formatPctChange }}</span> from LY-->
        <!--        </div>-->
      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"  class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span class="small-font">$</span>{{ storeData.rolling7 | formatShortMoney }}</h1>
        </div>
        <!--        <div class="uk-text-small small-text-css">-->
        <!--          <span :class="getTextColor(sysData.changePeriod)"-->
        <!--                :data-uk-icon="getIcon(sysData.changePeriod)">{{ sysData.changePeriod | formatPctChange }}</span> from LY-->
        <!--        </div>-->
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ storeData.period | formatShortMoney }}</h1>
        </div>
<!--        <div class="uk-text-small small-text-css">-->
<!--          <span :class="getTextColor(sysData.changePeriod)"-->
<!--                :data-uk-icon="getIcon(sysData.changePeriod)">{{ sysData.changePeriod | formatPctChange }}</span> from LY-->
<!--        </div>-->
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar" aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined" :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]" class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ storeData.ytd | formatShortMoney }}</h1>
        </div>
<!--        <div class="uk-text-small small-text-css">-->
<!--          <span :class="getTextColor(sysData.changeYtd)"-->
<!--                :data-uk-icon="getIcon(sysData.changeYtd)">{{ sysData.changeYtd | formatPctChange }}</span> from LY-->
<!--        </div>-->
      </div>
    </div>

  </div>

</template>

<script>

import SmallLoaderComponent from "../../../../Assets/js/Shared/SmallLoaderComponent.vue";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";

export default {
  name: "CompSalesCard",
  components: {
    SmallLoaderComponent,
    SmallSlimsLoader
  },

  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array,
    storeData: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {

    getTextColor(data) {
      if (data > 0)
        return "uk-text-success"
      else
        return "uk-text-danger"
    },

    getIcon(data) {
      if (data > 0)
        return "icon: triangle-up"
      else
        return "icon: triangle-down"
    },
  },


  filters: {
    formatShortMoney(num) {
      if (num > 1000 && num < 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' k' : Math.sign(num) * Math.abs(num);

      if (num > 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + ' m' : Math.sign(num) * Math.abs(num);
    },

    formatPctChange: function (number) {
      if (number === null)
        return 'Null';

      number = number.toString();
      number += '%';

      return number;
    }
  },

  mounted() {}

}
</script>

<style scoped>


.small-font {
  font-size: 20px;
}

div:not(#loader) {
  white-space: pre;
}

.sys-total-label {
  padding-top: 62px;
}
</style>