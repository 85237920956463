<template>
  <div v-if="dataSource !== null" class="uk-flex uk-flex-center">
    <DxDataGrid
        class="dg-height"
        :show-borders="true"
        :data-source="dataSource"
        @row-prepared="onRowPrepared"
    >
      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxScrolling mode="infinite"/>

      <DxColumn
          data-field="item"
          caption="Item Description"
          width="70%"/>

      <DxColumn
          data-field="goi"
          caption="GOI Chg"
          width="30%"
         :format="formatPercentage"/>

    </DxDataGrid>
  </div>
</template>

<script>
import {DxColumn, DxDataGrid, DxMasterDetail, DxFilterRow, DxHeaderFilter, DxScrolling} from 'devextreme-vue/data-grid';

import {createStore} from "devextreme-aspnet-data-nojquery";

export default {
  name: "PromotionDetail",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      columnData: null,
      promoWeeks: [],
      
    }
  },

  mounted() {
    this.loadData();
  },

  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },


  methods: {

    colName(index) {
      return "Week " + index;
    },

    colShow(index) {
      return this.promoWeeks.includes(index);
    },

    formatPercentage(number) {
      if (number === null)
        return 'Null';

      if (number > 0)
      {
        number = "+ " + number.toString() + "%";
        return number;
      }
      
      number = number.toString() + "%";

      return number;
    },

    loadData() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.isLoading = true;
        this.dataSource = createStore({
          loadUrl: `${url}/api/slims-report/promotions/get-promotion-baseline-datagrid`,
          onBeforeSend: (method, ajaxOptions) => {
            const token = this.getTokenSilently();
            ajaxOptions.headers = {
              Authorization: `Bearer ${token}`
            }
            ajaxOptions.data = {
              startDate: this.promotionData.startDate,
              endDate: this.promotionData.endDate,
              plu: this.promotionData.plu,
              clientId: clientId
            };
          }
        });

        this.isLoading = false;
 
    },

    onRowPrepared: function(e) {
      if (e.rowType === "data" &&  e.data.item === this.promotionData.itemName) {
        e.rowElement.bgColor = 'yellow';
      }
    },
  }
}
</script>

<style scoped>
.dg-height {
  height: 265px;
}
</style>