<template>
  <div class="uk-padding-small">
    <div class="uk-grid-medium uk-margin-bottom" data-uk-grid data-uk-height-match>
      
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>YTD's Detail </h4>{{ytdDates[0] + ` - ` + ytdDates[1]}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <ytd-level1 :is-corporate="isCorporate"></ytd-level1>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-3-5@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>YTD's Barchart</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <comp-sales-barchart :is-corporate="isCorporate" time-period="ytd-bar"></comp-sales-barchart>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-2-5">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>YTD's Store Ranking </h4>{{ytdDates[0] + ` - ` + ytdDates[1]}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <store-rank-data-grid :rank-type="'Ytd'"></store-rank-data-grid>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>

import YtdLevel1 from "@/Components/DetailPages/Parts/Ytd/Components/YtdLevel1.vue";
import StoreRankDataGrid from "@/Components/DetailPages/Parts/Shared/StoreRankDataGrid.vue";
import CompSalesBarchart from "@/Components/DetailPages/Parts/Shared/CompSalesBarchart.vue";

export default {
  name: "YtdDetailApp",
  components : {
    YtdLevel1,
    StoreRankDataGrid,
    CompSalesBarchart
  },
  props: {
    isCorporate: Boolean,
    ytdDates: Array
  },

  methods: {
    backToOverview () {
      this.eventHub.$emit("backToOverview", true);
    },
  }

}
</script>

<style scoped>

</style>