<template>
  <DxDataGrid
      v-if="dataSource !== null  && fmmFilter.length !== 0 && fadFilter.length !== 0 "
      :data-source="dataSource"
      :show-borders="true"
      height="700px"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      :column-auto-width="true">
  

    <DxScrolling
        mode="infinite"/>

    <DxExport
        :enabled="true"
    />

    <DxColumn
        data-field="franchiseGroup"
        />

    <DxColumn
        data-field="storeNumber"
    />

    <DxColumn
        data-field="storeName"
    />
    

    <DxColumn
        data-field="yesterday"
        cell-template="yesterday"
    />

    <DxColumn
        data-field="rolling7"
        cell-template="rolling7"
    />

    <DxColumn
        data-field="period"
        cell-template="period"
    />

    <DxColumn
        data-field="ytd"
        cell-template="ytd"
    />

    <template #yesterday="{ data }">
      <div>
      <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
        <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
        <span v-if="data.data.changeYesterday < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYesterday | formatMoneyNoCents }} </span>
        <span v-if="data.data.changeYesterday >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYesterday | formatMoneyNoCents }} </span>
      </div>

      <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
        <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
        <span v-if="data.data.changeYesterday < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYesterday | formatNumberNoDecimal }} </span>
        <span v-if="data.data.changeYesterday >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYesterday | formatNumberNoDecimal }} </span>
      </div>
      </div>
    </template>

    <template #period="{ data }">
      <div>
      <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
        <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
        <span v-if="data.data.changePeriod < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod | formatMoneyNoCents }} </span>
        <span v-if="data.data.changePeriod >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod | formatMoneyNoCents }} </span>
      </div>

        <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changePeriod < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod | formatNumberNoDecimal }} </span>
          <span v-if="data.data.changePeriod >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod | formatNumberNoDecimal }} </span>
        </div>
      </div>
    </template>


    <template #rolling7="{ data }">

    <div>
      <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
        <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
        <span v-if="data.data.changeRolling7 < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeRolling7 | formatMoneyNoCents }} </span>
        <span v-if="data.data.changeRolling7 >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeRolling7 | formatMoneyNoCents }} </span>
      </div>

      <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
        <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
        <span v-if="data.data.changeRolling7 < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeRolling7 | formatNumberNoDecimal }} </span>
        <span v-if="data.data.changeRolling7 >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeRolling7 | formatNumberNoDecimal }} </span>
      </div>
    </div>
      
    </template>

    <template #ytd="{ data }">
      <div>
        <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeYtd < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYtd | formatMoneyNoCents }} </span>
          <span v-if="data.data.changeYtd >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYtd | formatMoneyNoCents }} </span>
        </div>

        <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeYtd < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYtd | formatNumberNoDecimal }} </span>
          <span v-if="data.data.changeYtd >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYtd | formatNumberNoDecimal }} </span>
        </div>
      </div>
    </template>


  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import axios from "axios";

export default {
  name: "CompCalculatorGrid",
  components: {
    DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
  },

  data() {
    return {
      compType: "Comp",
      storeType: "Domestic System Total",
      metricType: "NetSales",
      fmmFilter: [],
      fadFilter: [],
      currentFmm: "All",
      currentFad: "All",
      
      dataSource: null,
      corpFranItems: [
        {
          text: "Domestic System Total",
          value: "Domestic System Total"
        },

        {
          text: "Corporate",
          value: "Corporate"
        },

        {
          text: "Domestic Franchise",
          value: "Domestic Franchise"
        },
      ],

      compItems: [
        {
          text: "Comp Type: Comp",
          value: "Comp"
        },
          
      ],

      metricItems: [
        {
          text: "Metric: Net Sales",
          value: "NetSales"
        },
        {
          text: "Metric: Guest Count",
          value: "GuestCount"
        },
      ]
    }
  },
  
  

  methods: {
    storeTypeChanged(e) {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.storeType = e.value

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: this.storeType,
            compType: this.compType,
            metricType: this.metricType,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

    onExporting(e) {

      let metricType = this.metricType;
      let storeType = this.storeType
      
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Comp % Store Overview Table');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 4; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            let cell2 = cell.value
            
            let cellValue = Number(cell2).toFixed(1);
            
            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: cellValue + "%"}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              storeType + ' Comp % Store Overview Table ' + '(' + metricType +')'+'.xlsx');
        });

      });
    },

    compTypeChanged(e) {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.compType = e.value

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: this.storeType,
            compType: this.compType,
            metricType: this.metricType,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

    metricTypeChanged(e) {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.metricType = e.value

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: this.storeType,
            compType: this.compType,
            metricType: this.metricType,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

   fmmChanged(e)
    {
      this.currentFmm = e.value;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: this.storeType,
            compType: this.compType,
            metricType: this.metricType,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

    fadChanged(e)
    {
      this.currentFad = e.value;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: this.storeType,
            compType: this.compType,
            metricType: this.metricType,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },
    
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
            location: 'before',
          }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select Corporate Or Franchise",
              items: this.corpFranItems,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.corpFranItems[0].value,
              onValueChanged: this.storeTypeChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select Comp Type",
              items: this.compItems,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.compItems[0].value,
              disabled: true,
              onValueChanged: this.compTypeChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a Metric Type",
              items: this.metricItems,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.metricItems[0].value,
              onValueChanged: this.metricTypeChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a FMM Rollup",
              items: this.fmmFilter,
              value: this.fmmFilter[0].value,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: this.fmmChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a FAD Rollup",
              items: this.fadFilter,
              value: this.fadFilter[0].value,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: this.fadChanged.bind(this)
            }
          },
      );
    },

  },

  mounted() {
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/contribution-comp-filter`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        type: 'FMM',
        clientId: clientId
      }
    }).then(response => {
      for (let x = 0; x < response.data.length; x++) {
        this.fmmFilter.push(response.data[x])
      }
      axios.get(`${url}/api/slims-report/contribution-comp-filter`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          type: 'FAD',
          clientId: clientId
        }
      }).then(response => {
        for (let x = 0; x < response.data.length; x++) {
          this.fadFilter.push(response.data[x])
        }
      });
    });
    
    
    this.dataSource = createStore({
      loadUrl: `${url}/api/slims-report/store-overview-comp-pct`,
      onBeforeSend: (method, ajaxOptions) => {
        const token = this.getTokenSilently();
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`
        }
        ajaxOptions.data = {
          storeType: this.storeType,
          compType: this.compType,
          metricType: this.metricType,
          fmm: this.currentFmm,
          fad: this.currentFad,
          clientId: clientId
        }
      }
    });
  }

}
</script>

<style scoped>
.top-metric {
  font-size: large;
}

.total-column {
  font-weight: bold;
}
</style>