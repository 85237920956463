<template>
  <DxDataGrid
      v-if="dataSource !== null"
      :data-source="dataSource"
      :show-borders="true"
      @row-prepared="onRowPrepared"
  >

    <DxExport
        :enabled="true"
    />

    <DxScrolling
        mode="infinite"/>

    <DxColumn
        data-field="channel"/>

    <DxColumn
        data-field="yesterday"
        :format="formatPercentage"/>

    <DxColumn
        data-field="rolling7"
        :format="formatPercentage"/>

    <DxColumn
        data-field="period"
        :format="formatPercentage"/>

    <DxColumn
        data-field="ytd"
        :format="formatPercentage"/>
    
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";
import numeral from "numeral";

export default {
  name: "ChannelMix",
  components: {
    DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
  },

  data() {
    return {
      currentGroupBy: "Channel",
      dataSource: null,
    }
  },

  created() {
    this.eventHub.$on('channel_filter', this.loadDataWithFilters)
    this.eventHub.$on('clearFilter', this.loadData)
  },

  beforeDestroy() {
    this.eventHub.$off('channel_filter');
    this.eventHub.$off('clearFilter');
  },
  
  methods: {
    onRowPrepared: function (e) {
      if (e.rowType === "data" && e.data.groupBy === 'Total') {
        e.rowElement.style.fontWeight = 'Bold';
      }
    },

    formatPercentage(value) {
      return numeral(value).format('0.0%');
    },

    isTotalRow(data) {
      if (data.rowType === "data" && data.data.groupBy === 'Total') {
        return "total-column";
      } else
        return "";
    },

    loadDataWithFilters (filterObject) {
      const url = this.getApiUrl();
      filterObject.clientId = this.getClientId();
      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/channel-mix`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = filterObject;
        }
      });
    },

    loadData () {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/channel-mix`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
    }
  },



  mounted() {
    this.loadData();
  }

}
</script>

<style scoped>
.top-metric {
  font-size: large;
}

.total-column {
  font-weight: bold;
}
</style>