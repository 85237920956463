<template>
  <DxVectorMap
      v-if="!isLoading"
      class="vector-map"
      :bounds="bounds"
  >

    <DxLayer
        :data-source="usa"
        :hover-enabled="false"
        name="areas"
        color-grouping-field="population"
    />

    <DxLayer
        :data-source="dataSource"
        :size-groups="sizeGroups"
        :min-size="20"
        :max-size="40"
        palette="Office"
        color-grouping-field="value"
        :color-groups="sizeGroups"
        :opacity="0.8"
        name="bubbles"
        element-type="bubble"
        data-field="value"
    />
    <DxLegend
        :customize-items="customizeItems"
        :customize-text="customizeText"
        marker-shape="circle"
    >
      <DxSource
          layer="bubbles"
          grouping="color"
      />
    </DxLegend>
    <DxTooltip
        :enabled="true"
        :customize-tooltip="customizeTooltip"
    />
  </DxVectorMap>
</template>

<script>
import {
  DxVectorMap,
  DxLabel,
  DxLayer,
  DxLegend,
  DxSource,
  DxTooltip,
} from 'devextreme-vue/vector-map';

import * as mapsData from 'devextreme/dist/js/vectormap-data/usa.js';
import axios from "axios";

export default {
  name: "GoiMap",
  components: {
    DxVectorMap,
    DxLabel,
    DxLayer,
    DxLegend,
    DxSource,
    DxTooltip,
  },

  data() {
    return {
      bounds: [-118, 52, -80, 20],
      locationData: null,
      isLoading: true,
      dataSource: {
        type: 'FeatureCollection',
        features: []
      },
      
      usa: mapsData.usa,
      sizeGroups: [0, 1, 2, 3, 4, 5, 6]
    }
  },

  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },

  methods: {

    customizeTooltip(info) {
      if (info.layer.type === 'marker') {
        return {text: info.attribute('tooltip')};
      }
      return null;
    },

    customizeText({index}) {
      return ['< 1', '1 to 2', '2 to 3', '3 to 4', ' > 5'][index];
    },

    customizeItems(items) {
      return items.reverse();
    },

  },

  mounted() {

    this.isLoading = true;

    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();
    let me = this;

    axios.get(`${url}/api/slims-report/promotions/get-promo-map`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        startDate: this.promotionData.startDate,
        endDate: this.promotionData.endDate,
        plu: this.promotionData.plu,
        clientId: clientId
      }
    }).then(response => {
      me.locationData = response.data;
    }).finally(function () {
      //Push Data Into The Datasource object 

      let tempObj = {};

      for (let x = 0; x < me.locationData.length; x++) {

        
        //This is how to structure data for vector map
        tempObj = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: []
          },

          properties: {
            text: "",
            value: "",
            tooltip: ""
          },
        };

        tempObj.geometry.coordinates.push(me.locationData[x].longitude);
        tempObj.geometry.coordinates.push(me.locationData[x].latitude);
        
        tempObj.properties.text = me.locationData[x].storeName;
        tempObj.properties.value = me.locationData[x].goi;

        tempObj.properties.tooltip = `<b>${me.locationData[x].storeName}</b><br>Goi: ${me.locationData[x].goi}%<br> Amount Sold: ${me.locationData[x].amtSold}`;

        me.dataSource.features.push(tempObj);
        tempObj = {};
      }

      me.isLoading = false;
    });


  },
}
</script>

<style scoped>
.vector-map {
  height: 440px;
}
</style>