<template>
  <div class="dx-field">
    <div class="center uk-align-center uk-margin-remove">
      <DxDropDownBox
          class="center"
          :show-clear-button="false"
          :data-source="dataSource"
          display-expr="value"
          value-expr="value"
          :placeholder="placeHolder"
          v-model:value="gridBoxValue"
      >
        <template #content="{ data }">
          <DxDataGrid
              :remote-operations="true"
              class="list-style"
              :data-source="dataSource"
              height="100%"
              @selection-changed="getSelectedData"
              @toolbar-preparing="onToolbarPreparing($event)"
              :ref="dataGridRefName">
            <DxSearchPanel
                :visible="true"
                placeholder="Search List"
            />
            <DxSelection mode="single"/>
            <DxPaging
                :enabled="true"
                :page-size="100"
            />
            <DxPager
                :visible="true"
            />
            <DxButton
                name="save"
                css-class="my-class"
            />
            <DxScrolling mode="infinite"
                         row-rendering-mode="virtual"/>
          </DxDataGrid>
        </template>
      </DxDropDownBox>
    </div>
  </div>
</template>


<script>
import { DxDataGrid, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxPager, DxHeaderFilter, DxSearchPanel, DxButton } from 'devextreme-vue/data-grid';
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import DxSpeedDialAction from 'devextreme-vue/speed-dial-action';

export default {
  name: "DropdownFilter",
  components: {
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxDropDownBox,
    DxPager,
    DxHeaderFilter,
    DxSearchPanel,
    DxSpeedDialAction,
    DxButton
  },

  data () {
    return {
      selectionsArr: '',
      gridBoxValue: 'YTD',
      dataGridRefName: 'dataGrid2',
      dataSource: [{value: 'Yesterday'}, {value: 'Rolling 7'}, {value: 'Period'}, {value: 'YTD'}],
    }
  },

  created () {
    this.eventHub.$on('clearFilter', this.clearSelection)
  },

  beforeDestroy() {
    this.eventHub.$off('clearFilter');
  },

  props: {
    emitName: String,
    placeHolder: String,
    api: String
  },
  methods: {

    onToolbarPreparing(e) {
      e.toolbarOptions.items.push({
            location: 'after',
          },
          {
            location: 'after',
            widget: 'dxButton',
            options: {

              icon: 'clear',
              hint: 'Clear Filters',
              onClick: this.clearSelection.bind(this)
            },
          });
    },


    clearSelection() {
      this.gridBoxValue = 'YTD';
      this.eventHub.$emit('channel_timeperiod', 'YTD');
    },

    getSelectedData: function({ selectedRowsData }) {
      this.selectionsArr = selectedRowsData[0].value;
      this.gridBoxValue = selectedRowsData[0].value;
      this.eventHub.$emit('channel_timeperiod', this.selectionsArr);

    },
  }


}
</script>

<style scoped>

</style>