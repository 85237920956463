<template>
  <div>

    <div v-if="isLoading && data === null" class="uk-flex uk-flex-column">
      <small-slims-loader></small-slims-loader>
    </div>


    <div v-if="data !== null" v-for="item in data" :value="item">
      <div>
       <recent-stores-metric :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates" :store-data="item" ></recent-stores-metric>
        <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold"></div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

import RecentStoresMetric from "./Components/RecentStoresMetric";
import SmallLoaderComponent from "../../../Assets/js/Shared/SmallLoaderComponent.vue";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";
import HelpInfo from "../../../Assets/js/Shared/HelpInfo.vue";
export default {
  name: "RecentStoresCard",
  components: {
    SmallLoaderComponent,
    SmallSlimsLoader,
    HelpInfo,
    RecentStoresMetric
  },

  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },


  data() {
    return {
      isLoading: false,
      data: null
    }
  },

  mounted() {

    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    //Franchise
    axios.get(`${url}/api/slims-report/recent-stores-card-data`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {clientId: clientId}
    })
        .then(response => {
          me.data = response.data;
        })
        .catch(err => {
        })
        .finally(() => {
          this.isLoading = false;
        });
  }
}
</script>

<style scoped>


</style>