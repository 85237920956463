<template>
  <div class="uk-padding-small">
    <div class="uk-grid-medium uk-margin-bottom" data-uk-grid data-uk-height-match>


      <div class="uk-width-1-3">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Promotion Category Info</h4>{{getDates(promotionData.startDate)}} - {{getDates(promotionData.endDate)}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <div class="uk-flex uk-flex-column uk-align-center">
                <promotion-base-line-vs-current class="uk-padding-small uk-padding-remove-horizontal" :promotion-data="promotionData"></promotion-base-line-vs-current>

                <baseline-category-data-grid :promotion-data="promotionData"></baseline-category-data-grid>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-2-3">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>GOI Per Day For {{ promotionData.itemName }}</h4> {{getDates(promotionData.startDate)}} - {{getDates(promotionData.endDate)}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <promotion-graph :promotion-data="promotionData"></promotion-graph>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Promotion Detail By Week</h4>{{getDates(promotionData.startDate)}} - {{getDates(promotionData.endDate)}} </div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <promotion-detail :promotion-data="promotionData"></promotion-detail>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-4">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>GOI Per Store</h4>{{getDates(promotionData.startDate)}} - {{getDates(promotionData.endDate)}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <store-goi-promo-data-grid :promotion-data="promotionData"></store-goi-promo-data-grid>
            </div>
          </div>
        </div>
      </div>


      <div class="uk-width-3-4">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>GOI Map </h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <goi-map :promotion-data="promotionData"></goi-map>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import PromotionDetail from "@/Components/DetailPages/Parts/PromotionDetail/Components/PromotionDetail.vue";
import PromotionGraph from "@/Components/DetailPages/Parts/PromotionDetail/Components/PromotionGraph.vue";
import PromotionBaseLineVsCurrent
  from "@/Components/DetailPages/Parts/PromotionDetail/Components/PromotionBaseLineVsCurrent.vue";
import BaselineCategoryDataGrid
  from "@/Components/DetailPages/Parts/PromotionDetail/Components/BaselineCategoryDataGrid.vue";
import GoiMap from "@/Components/DetailPages/Parts/PromotionDetail/Components/GoiMap.vue";
import StoreGoiPromoDataGrid from "@/Components/DetailPages/Parts/PromotionDetail/Components/StoreGoiPromoDataGrid.vue";

export default {
  name: "PromotionDetailApp",
  components: {PromotionDetail, PromotionGraph, PromotionBaseLineVsCurrent, BaselineCategoryDataGrid, GoiMap, StoreGoiPromoDataGrid},
  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },

  methods: {
    getDates(date) {
      if (new Date(date).getTime() > new Date().getTime()) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        return new Date(yesterday).toLocaleDateString("en-US");
      }


      return new Date(date).toLocaleDateString("en-US");
    }
  }
}
</script>

<style scoped>

</style>