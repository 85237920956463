<template>
  <div class="uk-flex uk-flex-column">

    <div class="uk-text-bold">Domestic Franchise Stores</div>

    <div v-if="showNoDataFran && !isLoading" class="uk-text-bold uk-padding">No Data Available With Selected Filters</div>

    <small-slims-loader v-if="isLoading || data === null"></small-slims-loader>

    <div v-if="!isLoading && data !== null && !showNoDataFran"
         class="uk-grid uk-grid-divider uk-grid-medium uk-padding-bottom-small " data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock"  :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalYesterday | formatShortMoney }} </h1>
        </div>
        <div class="uk-text-small">
          <span :class="getTextColor(data.changeYesterday)"
                :data-uk-icon="getIcon(data.changeYesterday)">{{ data.changeYesterday | formatPctChange }}</span> from LY
          <span :class="{'uk-text-success': data.budgetYesterday > 0, 'uk-text-danger': data.budgetYesterday < 0, 'container2': true }"> {{ data.budgetYesterday | formatPctChange }}<span :class="getTextColor(data.budgetYesterday)" :data-uk-icon="getIcon(data.budgetYesterday)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"  class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalRolling7 | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small">
          <span :class="{'uk-text-success': data.changeRolling7 > 0, 'uk-text-danger': data.changeRolling7 < 0, 'container': true }"> {{ data.changeRolling7 | formatPctChange }}<span :class="getTextColor(data.changeRolling7)" :data-uk-icon="getIcon(data.changeRolling7)"></span> <span class="after-text">from LY</span></span>

          <span :class="{'uk-text-success': data.budgetRolling7 > 0, 'uk-text-danger': data.budgetRolling7 < 0, 'container2': true }"> {{ data.budgetRolling7 | formatPctChange }}<span :class="getTextColor(data.budgetRolling7)" :data-uk-icon="getIcon(data.budgetRolling7)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalPeriod | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="{'uk-text-success': data.changePeriod > 0, 'uk-text-danger': data.changePeriod < 0, 'container': true }"> {{ data.changePeriod | formatPctChange }}<span :class="getTextColor(data.changePeriod)" :data-uk-icon="getIcon(data.changePeriod)"></span> <span class="after-text">from LY</span></span>

          <span :class="{'uk-text-success': data.budgetPeriod > 0, 'uk-text-danger': data.budgetPeriod < 0, 'container2': true }"> {{ data.budgetPeriod | formatPctChange }}<span :class="getTextColor(data.budgetPeriod)" :data-uk-icon="getIcon(data.budgetPeriod)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar" aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined" :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]" class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.totalYtd | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="{'uk-text-success': data.changeYtd > 0, 'uk-text-danger': data.changeYtd < 0, 'container': true }"> {{ data.changeYtd | formatPctChange }}<span :class="getTextColor(data.changeYtd)" :data-uk-icon="getIcon(data.changeYtd)"></span> <span class="after-text">from LY</span></span>

          <span :class="{'uk-text-success': data.budgetYtd > 0, 'uk-text-danger': data.budgetYtd < 0, 'container2': true }"> {{ data.budgetYtd | formatPctChange }}<span :class="getTextColor(data.budgetYtd)" :data-uk-icon="getIcon(data.budgetYtd)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i class="fa fa-credit-card" aria-hidden="true"></i></span>Check Avg.</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ data.averageCheck | formatNumberMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(data.changeCheck)"
                :data-uk-icon="getIcon(data.changeCheck)">{{ data.changeCheck | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i class="fa fa-user-circle" aria-hidden="true"></i></span>Guest Count</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font"></span>{{ data.totalGuestCount |formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(data.changeGuestCount)"
                :data-uk-icon="getIcon(data.changeGuestCount)">{{ data.changeGuestCount | formatPctChange }}</span> from LY
        </div>
      </div>

    </div>

    <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold">Corporate Stores</div>

    <div v-if="showNoDataCorp && !corpIsLoading" class="uk-text-bold uk-padding">No Data Available With Selected Filters</div>

    <small-slims-loader v-if="isLoading || corpData === null"></small-slims-loader>

    <div v-if="!isLoading && corpData!== null && !showNoDataCorp"
         class="uk-grid uk-grid-divider uk-grid-medium uk-padding-bottom-small " data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalYesterday | formatShortMoney }} </h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(corpData.changeYesterday)" :data-uk-icon="getIcon(corpData.changeYesterday)">{{corpData.changeYesterday | formatPctChange}}</span> from LY
          <span :class="{'uk-text-success': corpData.budgetYesterday > 0, 'uk-text-danger': corpData.budgetYesterday < 0, 'container2': true }"> {{ corpData.budgetYesterday | formatPctChange }}<span :class="getTextColor(corpData.budgetYesterday)" :data-uk-icon="getIcon(corpData.budgetYesterday)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"  class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalRolling7 | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">

          <span :class="{'uk-text-success': corpData.changeRolling7 > 0, 'uk-text-danger': corpData.changeRolling7 < 0, 'container': true }"> {{ corpData.changeRolling7 | formatPctChange }}<span :class="getTextColor(corpData.changeRolling7)" :data-uk-icon="getIcon(corpData.changeRolling7)"></span> <span class="after-text">from LY</span></span>

          <span :class="{'uk-text-success': corpData.budgetRolling7 > 0, 'uk-text-danger': corpData.budgetRolling7 < 0, 'container2': true }"> {{ corpData.budgetRolling7 | formatPctChange }}<span :class="getTextColor(corpData.budgetRolling7)" :data-uk-icon="getIcon(corpData.budgetRolling7)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalPeriod | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="{'uk-text-success': corpData.changePeriod > 0, 'uk-text-danger': corpData.changePeriod < 0, 'container': true }"> {{ corpData.changePeriod | formatPctChange }}<span :class="getTextColor(corpData.changePeriod)" :data-uk-icon="getIcon(corpData.changePeriod)"></span> <span class="after-text">from LY</span></span>

          <span :class="{'uk-text-success': corpData.budgetPeriod > 0, 'uk-text-danger': corpData.budgetPeriod < 0, 'container2': true }"> {{ corpData.budgetPeriod | formatPctChange }}<span :class="getTextColor(corpData.budgetPeriod)" :data-uk-icon="getIcon(corpData.budgetPeriod)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar" aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined" :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]" class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.totalYtd | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="{'uk-text-success': corpData.changeYtd > 0, 'uk-text-danger': corpData.changeYtd < 0, 'container': true }"> {{ corpData.changeYtd | formatPctChange }}<span :class="getTextColor(corpData.changeYtd)" :data-uk-icon="getIcon(corpData.changeYtd)"></span> <span class="after-text">from LY</span></span>

          <span :class="{'uk-text-success': corpData.budgetYtd > 0, 'uk-text-danger': corpData.budgetYtd < 0, 'container2': true }"> {{ corpData.budgetYtd | formatPctChange }}<span :class="getTextColor(corpData.budgetYtd)" :data-uk-icon="getIcon(corpData.budgetYtd)"></span> <span class="after-text">from Budget</span></span>
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i class="fa fa-credit-card"
                                                                                           aria-hidden="true"></i></span>Check Avg.</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font">$</span>{{ corpData.averageCheck | formatAverageCheck }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(corpData.changeCheck)"
                :data-uk-icon="getIcon(corpData.changeCheck)">{{ corpData.changeCheck | formatPctChange }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i class="fa fa-user-circle"
                                                                                           aria-hidden="true"></i></span>Guest Count</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span
              class="small-font"></span>{{ corpData.totalGuestCount | formatShortMoney }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(corpData.changeGuestCount)"
                :data-uk-icon="getIcon(corpData.changeGuestCount)">{{
              corpData.changeGuestCount | formatPctChange
            }}</span> from LY
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";

export default {
  name: "CompSalesCard",
  components: {
    SmallSlimsLoader
  },

  data() {
    return {
      data: null,
      corpData: null,
      budgetData: null,
      isLoading: false,
      corpIsLoading: false,
      budgetIsLoading: false,
      showNoDataCorp: false,
      showNoDataFran: false,

    }
  },

  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },

  created() {
    this.eventHub.$on('filterDetailKpi', this.loadDataFilters);
    this.eventHub.$on('clearFilter', this.loadData)
  },

  beforeDestroy() {
    this.eventHub.$off('filterDetailKpi');
    this.eventHub.$off('clearFilter');
  },

  methods: {
    goToDetail(metric) {
      this.eventHub.$emit("compDetailPage", metric);
    },

    getTextColor(data) {
      if (data > 0)
        return "uk-text-success"
      else
        return "uk-text-danger"
    },

    getIcon(data) {
      if (data > 0)
        return "icon: triangle-up"
      else
        return "icon: triangle-down"
    },

    loadDataFilters(filterObjectCorp, filterObjectFranchise) {
      this.isLoading = true;
      this.corpIsLoading = true;
      this.budgetIsLoading = true;

      let me = this;
      const token = this.getTokenSilently();

      const url = this.getApiUrl();
      const clientId = this.getClientId();

      filterObjectFranchise.clientId = clientId
      filterObjectCorp.clientId = clientId

      //Franchise Filter
      axios.get(`${url}/api/slims-report/overall-sales-kpi-detail`, {
        headers: {Authorization: `Bearer ${token}`},
        params: filterObjectFranchise
      })
          .then(response => {
            me.data = response.data[0];
          })
          .catch(err => {
          })
          .finally(() => {
            this.isLoading = false;
            this.showNoDataFran = me.data.totalYtd === 0;
          });

      //Corporate Filter
      axios.get(`${url}/api/slims-report/overall-sales-kpi-detail`, {
        headers: {Authorization: `Bearer ${token}`},
        params: filterObjectCorp
      })
          .then(response => {
            me.corpData = response.data[0];
          })
          .catch(err => {
          })
          .finally(() => {

            this.corpIsLoading = false;
            this.showNoDataCorp = me.corpData.totalYtd === 0;
          });
    },


    loadData() {
      this.isLoading = true;
      this.budgetIsLoading = true;
      this.corpIsLoading = true;

      let me = this;
      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      let corpFilterObject = {
        corpOrFranchise: 'Corporate',
        clientId: clientId
      };

      let franchiseFilterObject = {
        corpOrFranchise: 'Franchise',
        clientId: clientId
      }

      //Franchise
      axios.get(`${url}/api/slims-report/overall-sales-kpi-detail`, {
        headers: {Authorization: `Bearer ${token}`},
        params: franchiseFilterObject
      })
          .then(response => {
            me.data = response.data[0];
          })
          .catch(err => {
          })
          .finally(() => {
            this.isLoading = false;
            this.showNoDataFran = me.data.totalYtd === 0;
          });

      //Corporate
      axios.get(`${url}/api/slims-report/overall-sales-kpi-detail`, {
        headers: {Authorization: `Bearer ${token}`},
        params: corpFilterObject
      })
          .then(response => {
            me.corpData = response.data[0];
          })
          .catch(err => {
          })
          .finally(() => {
            this.corpIsLoading = false;
            this.showNoDataCorp = me.corpData.totalYtd === 0;
          });
    },
  },


  filters: {
    formatShortMoney(num) {
      if (num > 1000 && num < 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' k' : Math.sign(num) * Math.abs(num);

      if (num > 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + ' m' : Math.sign(num) * Math.abs(num);
    },


    formatPctChange: function (number) {
      if (number === null)
        return 'Null';

      number = number.toString();
      number += '%';

      return number;
    }
  },



  mounted() {
    // this.loadData();
  }
}
</script>

<style scoped>


.small-font {
  font-size: 20px;
}

.after-text {
  color: #666666;
}

.container {
  display: block;
}

.container2 {
  display: block;
}

div:not(#loader) {
  white-space: pre;
}

.sys-total-label {
  padding-top: 62px;
}
</style>