import { render, staticRenderFns } from "./AddPromotionModal.vue?vue&type=template&id=0e12ca3a&scoped=true"
import script from "./AddPromotionModal.vue?vue&type=script&lang=js"
export * from "./AddPromotionModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e12ca3a",
  null
  
)

export default component.exports