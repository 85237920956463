import { render, staticRenderFns } from "./TopBottomStoresMetric.vue?vue&type=template&id=55bf6b0c&scoped=true"
import script from "./TopBottomStoresMetric.vue?vue&type=script&lang=js"
export * from "./TopBottomStoresMetric.vue?vue&type=script&lang=js"
import style0 from "./TopBottomStoresMetric.vue?vue&type=style&index=0&id=55bf6b0c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bf6b0c",
  null
  
)

export default component.exports