<template>
  <div>
    <div class="uk-position-center uk-flex">
      <small-slims-loader v-if="isLoading"></small-slims-loader>
      <label v-if="isLoading">Loading Channel Summary...</label>
    </div>
    
    <div v-if="!isLoading" v-for="item in data" :value="item">
      <div class="promotion-padding">
        <channel-metric :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates" :channel-data="item"></channel-metric>
        <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold"></div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import PromotionsMetric from "@/Components/Homepage/Components/PromotionsMetric.vue";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";
import ChannelMetric from "@/Components/Homepage/Components/ChannelMetric.vue";

export default {
  name: "PromotionsCard",
  components: {PromotionsMetric, SmallSlimsLoader, ChannelMetric},

  data() {
    return {
      isLoading: false,
      data: null
    }
  },
  
  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },

  methods: {
    goToPromotionDetail(promotion) {
      // this.eventHub.$emit("showPromoDetail", promotion);
    }
  },

  mounted() {

    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/channel-overview`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {clientId: clientId}
    })
        .then(response => {
          me.data = response.data;
        })
        .catch(err => {
        })
        .finally(() => {
          me.isLoading = false;
        });
  },
}
</script>

<style scoped>

.selected-promotion:hover{
  border: 1px solid #1e87ef;
  cursor: pointer;
}

.promotion-padding {
  padding-left: 5px;
  padding-right: 5px;
}

</style>