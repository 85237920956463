<template>
  <div class="uk-flex uk-flex-column">

    <div class="uk-text-bold kpi-font-title">Item Category Baseline vs. Current GOI</div>

    <small-slims-loader v-if="isLoading"></small-slims-loader>

    <div v-if="!isLoading && data !== null" class="uk-grid uk-grid-divider uk-grid-medium uk-padding-bottom-small uk-flex-nowrap" data-uk-grid>
      <div>
        <span class="uk-text-small kpi-font-title"><span></span>Base</span>
        <div>
          <h1 class="uk-heading-primary kpi-font uk-margin-remove uk-text-primary">{{ data.baseGoi | formatPercentage }}<span
              class="small-font">%</span></h1>
        </div>
      </div>

      <div>
        <span class="uk-text-small kpi-font-title"><span></span>Current</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove kpi-margin uk-text-primary kpi-font">{{ data.goi | formatPercentage }}<span class="small-font">%</span></h1>
        </div>
      </div>


      <div>
        <span class="uk-text-small kpi-font-title"><span></span>Change</span>
        <div>
          <h1 :class="getTextFormat(data.goi, data.baseGoi)">{{ data.goiChange | formatPercentage }}<span class="small-font">%</span></h1>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";

export default {
  name: "BaseLineVsCurrent",
  components: {
    SmallSlimsLoader
  },

  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      isLoading: false,
      data: null
    }
  },

  methods: {
    
    getTextFormat (goi, baseGoi)
    {
      if (goi >= baseGoi)
        return "uk-heading-primary uk-margin-remove uk-text-success kpi-font";

      if (goi < baseGoi)
        return "uk-heading-primary uk-margin-remove uk-text-danger kpi-font";
    },

    getIcon(data) {
      if (data > 0)
        return "icon: triangle-up"
      else
        return "icon: triangle-down"
    },
  },


  filters: {
    formatPercentage(number) {
      if (number === null || number === undefined)
        return 'N / A';

      number = number.toFixed(1);
      number = number.toString();

      return number;
    },

    formatMoney: function (number) {
      if (number === null || number === undefined)
        return 'N / A';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },


  mounted() {

    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/promotions/promo-compare`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        plu: this.promotionData.plu,
        startDate: this.promotionData.startDate,
        endDate: this.promotionData.endDate,
        clientId: clientId
      }
    })
        .then(response => {
           me.data = response.data[0];
        })
        .catch(err => {
        })
        .finally(() => {
          me.isLoading = false;
        });
  },
}
</script>

<style scoped>
.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.kpi-font {
  font-size: 2.3vw;
}

.kpi-font-title {
  font-size: 1.2vw;
}

.kpi-margin {
  margin-right: .5vw;
}

.arrow {
  size: 30px;
}

div {
  white-space: pre;
}

.small-font {
  font-size: 20px;
}

</style>