<template>
  <div class="uk-flex uk-flex-column">

    <div class="uk-text-bold">{{ channelData.channel }}</div>

    <div v-if="channelData !== null"
         :class="{'uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap': true, 'uk-grid-small': windowWidth < 1600, 'uk-grid-medium': windowWidth >= 1600}" data-uk-grid>
      <div>
                <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{ channelData.pctChangeYesterday | formatPercentageChannel }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(channelData.changeYesterday)"
                :data-uk-icon="getIcon(channelData.changeYesterday)">{{ channelData.changeYesterday | formatMoneyNoCents }}</span> from LY
        </div>
      </div>

      <div>
                  <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
                      v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"
                      class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{ channelData.pctChangeRolling7 | formatPercentageChannel }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(channelData.changeRolling7)"
                :data-uk-icon="getIcon(channelData.changeRolling7)">{{ channelData.changeRolling7 | formatMoneyNoCents }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
            v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"
            class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{ channelData.pctChangePeriod | formatPercentageChannel }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(channelData.changePeriod)"
                :data-uk-icon="getIcon(channelData.changePeriod)">{{ channelData.changePeriod | formatMoneyNoCents }}</span> from LY
        </div>
      </div>

      <div>
      <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
          v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar"
          aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined"
                                    :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]"
                                    class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{ channelData.pctChangeYtd | formatPercentageChannel }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(channelData.changeYtd)"
                :data-uk-icon="getIcon(channelData.changeYtd)">{{ channelData.changeYtd | formatMoneyNoCents }}</span> from LY
        </div>
      </div>


      <div>
         <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
             v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"
             class="fa fa-credit-card" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-credit-card" aria-hidden="true"/></span>Check Avg.</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary"><span class="small-font">$</span>{{ channelData.averageCheck | formatAverageCheck }}</h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(channelData.pctChangeCheck)"
                :data-uk-icon="getIcon(channelData.pctChangeCheck)">{{ channelData.pctChangeCheck | formatPercentageString }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
            v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"
            class="fa fa-user-circle" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-user-circle" aria-hidden="true"/></span>Guest Count</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{ channelData.pctChangeGuestCount | formatPercentageChannel }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(channelData.changeGuestCount)"
                :data-uk-icon="getIcon(channelData.changeGuestCount)">{{ channelData.changeGuestCount | formatShortNumber }} Guests</span> from LY
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>

import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";

export default {
  name: "ChannelMetric",
  components: {
    SmallSlimsLoader
  },
  
  data () {
    return {
      windowWidth: window.innerWidth,
    }
  },

  props: {
    channelData: {
      type: Object,
      default: () => ({}),
    },
      yesterdayDates: Array,
      rolling7Dates: Array,
      periodDates: Array,
      ytdDates: Array
    
  },  
  
  created() {
    window.addEventListener("resize", this.windowResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },

  methods: {
    getTextColor(data) {
      if (data > 0)
        return "uk-text-success"
      else
        return "uk-text-danger"
    },

    windowResize () 
    {
      this.windowWidth = window.innerWidth;
    },

    getIcon(data) {
      if (data > 0)
        return "icon: triangle-up"
      else
        return "icon: triangle-down"
    },
  },


  filters: {

    
    formatPercentageChannel (number) {
      if (number === null)
        return 'Null';

      number = number.toFixed(1);

      return number;
    },

    formatShortMoney(num) {
      if (num > 1000 && num < 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' k' : Math.sign(num) * Math.abs(num);

      if (num > 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + ' m' : Math.sign(num) * Math.abs(num);
      
      else return num;
    },
  }
}
</script>

<style scoped>
.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

div {
  white-space: pre;
}

.small-font {
  font-size: 20px;
}

</style>