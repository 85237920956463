<template>
  <div>

    <div v-if="isLoading && data === null" class="uk-flex uk-flex-column">
      <div class="uk-text-bold">Domestic System Total</div>
      <small-slims-loader></small-slims-loader>
      <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold">Domestic Franchise</div>
      <small-slims-loader></small-slims-loader>
      <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold">Corporate Stores</div>
      <small-slims-loader></small-slims-loader>
    </div>


    <div v-if="data !== null" v-for="item in data" :value="item">
      <div>
        <comp-sales-metric :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates" :comp-data="item"></comp-sales-metric>
        <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold"></div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import SmallLoaderComponent from "../../../Assets/js/Shared/SmallLoaderComponent.vue";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";
import HelpInfo from "../../../Assets/js/Shared/HelpInfo.vue";
import CompSalesMetric from "@/Components/Homepage/Components/CompSalesMetric.vue";

export default {
  name: "CompSalesCard",
  components: {
    SmallLoaderComponent,
    SmallSlimsLoader,
    HelpInfo,
    CompSalesMetric
  },

  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },


  data() {
    return {
      isLoading: false,
      data: null
    }
  },

  mounted() {

    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const clientId = this.getClientId();

    //Franchise
    const url = this.getApiUrl();
    axios.get(`${url}/api/slims-report/comp-sales/comp-kpi-data`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        metric: 'Netsales',
        clientId: clientId
      }
    })
        .then(response => {
          me.data = response.data;
        })
        .catch(err => {
        })
        .finally(() => {
          this.isLoading = false;
        });
  }
}
</script>

<style scoped>


</style>