<template>
  <div v-if="dataSource !== null">
    <DxDataGrid
        height="440px"
        :show-borders="true"
        :data-source="dataSource"
    >
      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxScrolling mode="infinite"/>

      <DxColumn
          data-field="storeName"
          caption="Store"/>

      <DxColumn
          data-field="goi"
          caption="GOI %"
          :format="formatPercentage"/>

      <DxColumn
          data-field="amtSold"
          caption="Amount Sold"/>

      <DxColumn
          data-field="pspd"
          caption="PSPD"/>

    </DxDataGrid>
  </div>
</template>

<script>
import {DxColumn, DxDataGrid, DxMasterDetail, DxFilterRow, DxHeaderFilter, DxScrolling} from 'devextreme-vue/data-grid';

import {createStore} from "devextreme-aspnet-data-nojquery";

export default {
  name: "PromotionDetail",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      columnData: null,
      promoWeeks: [],
      format: "#.##%"
    }
  },

  mounted() {
    this.loadData();
  },

  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },

  filters: {
    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toString() + "%";

      return number;
    },


    formatMoney(number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  methods: {
    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toString() + "%";

      return number;
    },
    
    colShow(index) {
      return this.promoWeeks.includes(index);
    },

    loadData() {
      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/promotions/get-promo-store-table`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            startDate: this.promotionData.startDate,
            endDate: this.promotionData.endDate,
            plu: this.promotionData.plu,
            clientId: clientId
          };
        }
      });

      this.isLoading = false;

    },
  }
}
</script>

<style scoped>
.dg-height {
  height: 265px;
}
</style>