<template>
  <div>
    <div class="uk-position-center uk-flex">
      <small-slims-loader v-if="isLoading"></small-slims-loader>
      <label v-if="isLoading">Loading Top/Bottom 5 Groups Summary...</label>
    </div>

    <div v-if="!isLoading" v-for="item in data" :value="item">
      <div class="promotion-padding">
        <top-bottom-stores-metric :store-data="item"></top-bottom-stores-metric>
        <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold"></div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import SmallSlimsLoader from "@/Components/Homepage/Components/SmallSlimsLoader.vue";
import PromotionsMetric from "@/Components/Homepage/Components/PromotionsMetric.vue";
import TopBottomStoresMetric from "@/Components/Homepage/Components/TopBottomStoresMetric.vue";

export default {
  name: "TopStoresCard",
  components: {PromotionsMetric, SmallSlimsLoader, TopBottomStoresMetric},

  data() {
    return {
      isLoading: false,
      data: null
    }
  },
  props : {
    direction: String
  },
  
  mounted() {

    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/comp-sales/top-bottom-5-groups`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        direction: this.direction,
        clientId: clientId
      }
    })
        .then(response => {
          me.data = response.data;
        })
        .catch(err => {
        })
        .finally(() => {
          me.isLoading = false;
        });
  },
}
</script>

<style scoped>

.selected-promotion:hover{
  border: 1px solid #1e87ef;
  cursor: pointer;
}

.promotion-padding {
  padding-left: 5px;
  padding-right: 5px;
}

</style>