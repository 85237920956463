<template>
  <DxDataGrid
      v-if="(!isCorporate && fmmFilter.length !== 0 && fadFilter.length !== 0) || (isCorporate) "
      :show-borders="true"
      :data-source="dataSource"
      height="600px"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)">

    <DxExport
        :enabled="true"
    />

    <DxFilterRow :visible="true"/>

    <DxHeaderFilter :visible="true"/>

    <DxScrolling mode="infinite"/>

    <DxColumn
        data-field="groupName"
        caption="Group Name"/>

    <DxColumn
        data-field="pctChangeSeven"
        :caption="getDate(7)"
        cell-template="Seven"/>

    <DxColumn
        data-field="pctChangeSix"
        :caption="getDate(6)"
        cell-template="Six"/>

    <DxColumn
        data-field="pctChangeFive"
        :caption="getDate(5)"
        cell-template="Five"/>

    <DxColumn
        data-field="pctChangeFour"
        :caption="getDate(4)"
        cell-template="Four"/>

    <DxColumn
        data-field="pctChangeThree"
        :caption="getDate(3)"
        cell-template="Three"/>

    <DxColumn
        data-field="pctChangeTwo"
        :caption="getDate(2)"
        cell-template="Two"/>

    <DxColumn
        data-field="pctChangeOne"
        :caption="getDate(1)"
        cell-template="One"/>

    <DxColumn
        data-field="pctChangeTotal"
        caption="Total"
        cell-template="Total"/>

    <template #Seven="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeSeven < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
            data.data.changeSeven | formatMoney
          }} </span>
        <span v-if="data.data.changeSeven >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
            data.data.changeSeven | formatMoney
          }} </span>
      </div>
    </template>

    <template #Six="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeSix < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeSix | formatMoney }} </span>
        <span v-if="data.data.changeSix >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeSix | formatMoney }} </span>
      </div>
    </template>

    <template #Five="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeFive < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeFive | formatMoney }} </span>
        <span v-if="data.data.changeFive >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeFive | formatMoney }} </span>
      </div>
    </template>

    <template #Four="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeFour < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeFour | formatMoney }} </span>
        <span v-if="data.data.changeFour >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeFour | formatMoney }} </span>
      </div>
    </template>


    <template #Three="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeThree < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeThree | formatMoney }} </span>
        <span v-if="data.data.changeThree >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeThree | formatMoney }} </span>
      </div>
    </template>

    <template #Two="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeSix < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeSix | formatMoney }} </span>
        <span v-if="data.data.changeSix >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeSix | formatMoney }} </span>
      </div>
    </template>

    <template #One="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeOne < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeOne | formatMoney }} </span>
        <span v-if="data.data.changeOne >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeOne | formatMoney }} </span>
      </div>
    </template>


    <template #Total="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeTotal < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
        <span v-if="data.data.changeTotal >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
      </div>
    </template>

    <DxMasterDetail
        :enabled="true"
        template="masterDetailTemplate"
    />

    <template #masterDetailTemplate="{ data }">

      <rolling7-level2
          class="div-background"
          :template-data="data"
          :is-corporate="isCorporate"
      />

    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';

import {createStore} from "devextreme-aspnet-data-nojquery";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from 'file-saver';
import axios from "axios";
import Rolling7Level2 from "@/Components/DetailPages/Parts/Rolling7/Components/Rolling7Level2.vue";

export default {
  name: "YesterdayLevel1",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    Rolling7Level2,
    DxExport
  },

  data() {
    return {
      dataSource: null,
      fmmFilter: [],
      fadFilter: [],
      currentFmm: "All",
      currentFad: "All",
    }
  },

  mounted() {
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    if (!this.isCorporate) {
      axios.get(`${url}/api/slims-report/contribution-comp-filter`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          type: 'FMM',
          clientId: clientId
        }
      }).then(response => {
        for (let x = 0; x < response.data.length; x++) {
          this.fmmFilter.push(response.data[x])
        }
        axios.get(`${url}/api/slims-report/contribution-comp-filter`, {
          headers: {Authorization: `Bearer ${token}`},
          params: {
            type: 'FAD',
            clientId: clientId
          }
        }).then(response => {
          for (let x = 0; x < response.data.length; x++) {
            this.fadFilter.push(response.data[x])
          }
        });
      });
    }

    this.loadData();
  },

  props: {
    isCorporate: Boolean,
  },

  filters: {
    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toFixed(1);
      number = number.toString() + "%";

      return number;
    },


    formatMoney(number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  methods: {

    fmmChanged(e) {
      this.currentFmm = e.value;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/comp-sales/rolling7-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

    fadChanged(e) {
      this.currentFad = e.value;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/comp-sales/rolling7-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            fmm: this.currentFmm,
            fad: this.currentFad,
            clientId: clientId
          }
        }
      });
    },

    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
            location: 'before',
          }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a FMM Rollup",
              items: this.fmmFilter,
              value: this.fmmFilter[0].value,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: this.fmmChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a FAD Rollup",
              items: this.fadFilter,
              value: this.fadFilter[0].value,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: this.fadChanged.bind(this)
            }
          },
      );

    },

    getDate(daysAgo) {
      let newDate = new Date();
      newDate.setDate(newDate.getDate() - daysAgo);
      let stringDate = newDate.toISOString()
      const dateOnly = stringDate.split('T');
      return dateOnly[0].toString();
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Franchise Groups Comp % Rolling 7');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 2; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: cell.value + "%"}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              "Franchise Groups Comp % Rolling 7.xlsx");
        });

      });
    },

    loadData() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/comp-sales/rolling7-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            clientId: clientId
          };
        }
      });
    }
  }
}
</script>

<style scoped>

.top-metric {
  font-size: large;
}

.div-background {
  border: solid 1px black;
}

</style>