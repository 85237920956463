<template>
  <div v-if="columnData !== null && dataSource !== null">
    <DxDataGrid
        :show-borders="true"
        :data-source="dataSource"
        height="650px"
        @row-prepared="onRowPrepared"
        :column-auto-width="true">


      <DxHeaderFilter :visible="true"/>

      <DxExport
          :enabled="true"
      />

      <DxScrolling
          column-rendering-mode="virtual"
          mode="infinite"/>

      <DxColumn
          data-field="level4"
          caption="Item"
          width="200px"/>

      <DxColumn data-field="baseline" caption="Base" cell-template="baseCol" :format="formatPercentage"/>

      <DxColumn v-if="colShow(1)" data-field="week1" :caption="colName(1)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(2)" data-field="week2" :caption="colName(2)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(3)" data-field="week3" :caption="colName(3)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(4)" data-field="week4" :caption="colName(4)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(5)" data-field="week5" :caption="colName(5)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(6)" data-field="week6" :caption="colName(6)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(7)" data-field="week7" :caption="colName(7)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(8)" data-field="week8" :caption="colName(8)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(9)" data-field="week9" :caption="colName(9)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(10)" data-field="week10" :caption="colName(10)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(11)" data-field="week11" :caption="colName(11)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(12)" data-field="week12" :caption="colName(12)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(13)" data-field="week13" :caption="colName(13)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(14)" data-field="week14" :caption="colName(14)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(15)" data-field="week15" :caption="colName(15)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(16)" data-field="week16" :caption="colName(16)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(17)" data-field="week17" :caption="colName(17)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(18)" data-field="week18" :caption="colName(18)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(19)" data-field="week19" :caption="colName(19)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(20)" data-field="week20" :caption="colName(20)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(21)" data-field="week21" :caption="colName(21)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(22)" data-field="week22" :caption="colName(22)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(23)" data-field="week23" :caption="colName(23)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(24)" data-field="week24" :caption="colName(24)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(25)" data-field="week25" :caption="colName(25)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(26)" data-field="week26" :caption="colName(26)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(27)" data-field="week27" :caption="colName(27)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(28)" data-field="week28" :caption="colName(28)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(29)" data-field="week29" :caption="colName(29)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(30)" data-field="week30" :caption="colName(30)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(31)" data-field="week31" :caption="colName(31)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(32)" data-field="week32" :caption="colName(32)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(33)" data-field="week33" :caption="colName(33)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(34)" data-field="week34" :caption="colName(34)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(35)" data-field="week35" :caption="colName(35)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(36)" data-field="week36" :caption="colName(36)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(37)" data-field="week37" :caption="colName(37)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(38)" data-field="week38" :caption="colName(38)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(39)" data-field="week39" :caption="colName(39)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(40)" data-field="week40" :caption="colName(40)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(41)" data-field="week41" :caption="colName(41)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(42)" data-field="week42" :caption="colName(42)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(43)" data-field="week43" :caption="colName(43)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(44)" data-field="week44" :caption="colName(44)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(45)" data-field="week45" :caption="colName(45)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(46)" data-field="week46" :caption="colName(46)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(47)" data-field="week47" :caption="colName(47)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(48)" data-field="week48" :caption="colName(48)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(49)" data-field="week49" :caption="colName(49)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(50)" data-field="week50" :caption="colName(50)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(51)" data-field="week51" :caption="colName(51)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(52)" data-field="week52" :caption="colName(52)" :format="formatPercentage"/>
      <DxColumn v-if="colShow(53)" data-field="week53" :caption="colName(53)" :format="formatPercentage"/>

      <template #baseCol="{ data }">
        <div class="base-col">
          {{ data.value | formatPercentageString }}
        </div>
      </template>

      <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
      />

      <template #masterDetailTemplate="{ data }">
        <div class="div-background">

          <master-detail-tabs
              class="div-background"
              :template-data="data"
              :promotion-data="promotionData"></master-detail-tabs>
        </div>
      </template>

    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import MasterDetailTabs from "@/Components/DetailPages/Parts/PromotionDetail/Components/MasterDetailTabs.vue";
import PromotionDetailPerStore
  from "@/Components/DetailPages/Parts/PromotionDetail/Components/PromotionDetailPerStore.vue";

export default {
  name: "PromotionDetail",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    DxExport,
    PromotionDetailPerStore,
    MasterDetailTabs
  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      columnData: null,
      promoWeeks: [],
    }
  },

  mounted() {
    this.loadData();
  },

  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },

  filters: {

    formatMoney(number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  methods: {

    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toString() + "%";

      return number;
    },


    colName(index) {
      return 'Week ' + index.toString();
    },

    colShow(index) {

      if (index <= this.promoWeeks.length)
        return true;

      else
        return false;

    },

    loadData() {

      this.isLoading = true;

      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      let me = this;
      axios.get(`${url}/api/slims-report/promotions/get-promotion-range`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          startDate: me.promotionData.startDate,
          endDate: me.promotionData.endDate,
          clientId: clientId
        }
      }).then(response => {
        me.columnData = response.data;
      }).finally(function () {


        for (let x = 0; x < me.columnData.length; x++) {
          me.promoWeeks.push(me.columnData[x].weekNum);
        }


        me.dataSource = createStore({
          loadUrl: `${url}/api/slims-report/promotions/get-promotion-table`,
          onBeforeSend: (method, ajaxOptions) => {
            const token = me.getTokenSilently();
            ajaxOptions.headers = {
              Authorization: `Bearer ${token}`
            }
            ajaxOptions.data = {
              startDate: me.promotionData.startDate,
              endDate: me.promotionData.endDate,
              plu: me.promotionData.plu,
              clientId: clientId
            };
          }
        });

        me.isLoading = false;
      });
    },

    onRowPrepared: function (e) {
      if (e.rowType === "data" && e.data.level4 === this.promotionData.itemName) {
        e.rowElement.bgColor = 'yellow';
      }
    },
  }
}
</script>

<style scoped>
.base-col {
  font-weight: bold;
}
</style>