<template>
  <div class="uk-padding-small">

    <div class="uk-grid-medium uk-margin-bottom" data-uk-grid data-uk-height-match>


      <div class="uk-width-1-1@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Rolling 7's Detail</h4> {{rolling7Dates[0] + ` - ` + rolling7Dates[1]}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
             <rolling7-level1 :is-corporate="isCorporate"></rolling7-level1>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-3-5@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Rolling 7's Barchart</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <comp-sales-barchart :is-corporate="isCorporate" time-period="rolling7-bar"></comp-sales-barchart>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-2-5@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Rolling 7's Store Ranking </h4>{{rolling7Dates[0] + ` - ` + rolling7Dates[1]}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
             <store-rank-data-grid :rank-type="'Rolling7'"></store-rank-data-grid>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>

import CompSalesBarchart from "@/Components/DetailPages/Parts/Shared/CompSalesBarchart.vue";
import Rolling7Level1 from "@/Components/DetailPages/Parts/Rolling7/Components/Rolling7Level1.vue";
import StoreRankDataGrid from "@/Components/DetailPages/Parts/Shared/StoreRankDataGrid.vue";

export default {
  name: "Rolling7App",
  components : {
    Rolling7Level1,
    StoreRankDataGrid,
    CompSalesBarchart
  },
  props: {
    isCorporate: Boolean,
    rolling7Dates: Array,
  },

  methods: {
    backToOverview () {
      this.eventHub.$emit("backToOverview", true);
    },
  }

}
</script>

<style scoped>

</style>