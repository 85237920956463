<template>
  <div class="uk-padding-small">
    <div class="uk-grid-medium uk-margin-bottom" data-uk-grid data-uk-height-match>
      
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@l">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Summary Metrics</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
             <overall-sales-detail-kpi :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates"></overall-sales-detail-kpi>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-3-4@s">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto uk-flex">
                <h4>Overall Sales</h4>
              </div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <overall-sales-by-week></overall-sales-by-week>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-4@s">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@l">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Filters</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <filter-loader></filter-loader>
            </div>
          </div>
        </div>
      </div>
      
      
      <div class="uk-width-3-4@s">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div v-if="startDate === ''" class="uk-width-auto"><h4>Daily Trend View</h4></div>
              <div v-if="startDate !== ''" class="uk-width-auto"><h4>Daily Trend View</h4> {{startDate}} - {{endDate}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container trend-height">
              <weekly-trend></weekly-trend>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-4@s">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div v-if="startDate === ''" class="uk-width-auto"><h4>Store Type Breakdown</h4></div>
              <div v-if="startDate !== ''" class="uk-width-auto"><h4>Store Type Breakdown</h4> {{startDate}} - {{endDate}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container pie-chart">
              <store-type-breakdown-barchart></store-type-breakdown-barchart>
            </div>
          </div>
        </div>
      </div>
      

    </div>
  </div>
</template>

<script>
import {
  DxRangeSelector,
  DxMargin,
  DxScale,
  DxMinorTick,
  DxMinorTickInterval,
  DxTickInterval,
  DxMinRange,
  DxMaxRange,
  DxSliderMarker,
} from 'devextreme-vue/range-selector';
import StoreTypeBreakdownBarchart
  from "@/Components/DetailPages/Parts/OverallSalesDetails/Components/StoreTypeBreakdownBarchart.vue";
import OverallSalesByWeek from "@/Components/DetailPages/Parts/OverallSalesDetails/Components/OverallSalesByWeek.vue";
import FilterLoader from "@/Components/DetailPages/Parts/OverallSalesDetails/Components/FilterLoader.vue";
import OverallSalesDetailKpi
  from "@/Components/DetailPages/Parts/OverallSalesDetails/Components/OverallSalesDetailKpi.vue";
import WeeklyTrend from "@/Components/DetailPages/Parts/OverallSalesDetails/Components/WeeklyTrend.vue";
export default {
  name: "OverallSalesDetail",
  components: {
    StoreTypeBreakdownBarchart,
    DxRangeSelector,
    DxMargin,
    DxScale,
    DxMinorTick,
    DxMinorTickInterval,
    DxTickInterval,
    DxMinRange,
    DxMaxRange,
    DxSliderMarker,
    OverallSalesByWeek,
    FilterLoader,
    OverallSalesDetailKpi,
    WeeklyTrend
  },

  data() {
    return {
      isLoading: true,
      data: null,
      range: [],
      startDate: '',
      endDate: '',
      firstRun: true
    }
  },

  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },
  
  created() {
    this.eventHub.$on('filter', this.updateDates)
  },

  beforeDestroy() {
    this.eventHub.$on('filter', this.updateDates)
  },

  methods: {
    backToOverview() {
      this.eventHub.$emit("backToOverview", true);
    },
    
    updateDates: function (filter) {
      this.startDate = new Date(filter.startDate).toLocaleDateString("en-US");
      this.endDate = new Date(filter.endDate).toLocaleDateString("en-US");
    },
  }

}
</script>

<style scoped>
.grid-buttons {
  height: 30px;
}

.trend-height {
  height: 550px;
}
</style>