<template>
  <div class="dx-field">
    <div class="center uk-align-center uk-margin-remove">
      <DxDropDownBox
          class="center"
          :show-clear-button="false"
          :data-source="dataSource"
          display-expr="value"
          value-expr="value"
          :placeholder="placeHolder"
          v-model:value="gridBoxValue"
      >
        <template #content="{ data }">
          <DxDataGrid
              :remote-operations="true"
              class="list-style"
              :data-source="dataSource"
              height="100%"
              @selection-changed="getSelectedData"
              @toolbar-preparing="onToolbarPreparing($event)"
              :ref="dataGridRefName">
            <DxSearchPanel
                :visible="true"
                placeholder="Search List"
            />
            <DxSelection mode="multiple"/>
            <DxPaging
                :enabled="true"
                :page-size="100"
            />
            <DxPager
                :visible="true"
            />
            <DxButton
                name="save"
                css-class="my-class"
            />
            <DxScrolling mode="infinite"
                         row-rendering-mode="virtual"/>
          </DxDataGrid>
        </template>
      </DxDropDownBox>
    </div>
  </div>
</template>


<script>
import { DxDataGrid, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxPager, DxHeaderFilter, DxSearchPanel, DxButton } from 'devextreme-vue/data-grid';
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import DxSpeedDialAction from 'devextreme-vue/speed-dial-action';
import {createStore} from "devextreme-aspnet-data-nojquery";

export default {
  name: "DropdownFilter",
  components: {
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxDropDownBox,
    DxPager,
    DxHeaderFilter,
    DxSearchPanel,
    DxSpeedDialAction,
    DxButton
  },
  
  data () {
    return {
      selectionsArr: [],
      gridBoxValue: [],
      dataGridRefName: 'dataGrid2',
      dataSource: null,
    }
  },

  created () {
    this.eventHub.$on('clearFilter', this.clearSelection)
  },

  beforeDestroy() {
    this.eventHub.$off('clearFilter');
  },
  
  props: {
    emitName: String,
    placeHolder: String,
    api: String
  },
  
  mounted() {
    const url = this.getApiUrl();
    const clientId = this.getClientId();
    this.dataSource = createStore({
      loadMode: "raw",
      loadUrl: `${url}/api/slims-report/get-filter`,
      onBeforeSend: (method, ajaxOptions) => {
        const token = this.getTokenSilently();
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`
        }
        ajaxOptions.data = {
          filterType: this.api,
          clientId: clientId
        };
      }
    });
  },
  
  methods: {

    onToolbarPreparing(e) {
      e.toolbarOptions.items.push({
            location: 'after',
          },
          {
            location: 'after',
            widget: 'dxButton',
            options: {

              icon: 'clear',
              hint: 'Clear Filters',
              onClick: this.clearSelection.bind(this)
            },
          });
    },

    saveFilter: function () {
      this.gridBoxValue = this.selectionsArr
      this.previousValue = this.selectionsArr.length;
      this.eventHub.$emit(this.emitName, this.selectionsArr);
    },

    clearSelection() {
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      dataGrid.clearSelection();
      this.saveFilter();
    },
    
    getSelectedData: function({ selectedRowsData }) {

      this.selectionsArr = []

      if (selectedRowsData.length !== 0) {
        for (let x = 0; x < selectedRowsData.length; x++) {
          this.selectionsArr.push(selectedRowsData[x].value)
        }
      }

      this.saveFilter()
    },
  }
  
  
}
</script>

<style scoped>

</style>