import { render, staticRenderFns } from "./PeriodLevel1.vue?vue&type=template&id=31d91ba9&scoped=true"
import script from "./PeriodLevel1.vue?vue&type=script&lang=js"
export * from "./PeriodLevel1.vue?vue&type=script&lang=js"
import style0 from "./PeriodLevel1.vue?vue&type=style&index=0&id=31d91ba9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d91ba9",
  null
  
)

export default component.exports