<template>
  <div>

    <div class="dx-field-label selectbox-css">Aggregation Interval
      <DxSelectBox :items="items"
                   v-model:value="value"
                   width="200px"
                   @value-changed="aggregationChanged"/>

    </div>


    <small-slims-loader v-if="isLoading" class="uk-padding-small uk-position-top-right"> Loading Graph
    </small-slims-loader>
    <DxChart
        :data-source="this.data"
        class="chart-css"
    >
      <DxLegend
          position="outside"
          horizontal-alignment="center"
          vertical-alignment="bottom"
      />

      <DxCommonSeriesSettings
          argument-field="date"
          type="line">
        <DxPoint :visible="false"/>
      </DxCommonSeriesSettings>

      <DxTooltip
          :enabled="true"
          :shared="true"
          :customizeTooltip="customizeTooltip"
      />

      <DxArgumentAxis :aggregation-interval="value">
      </DxArgumentAxis>

      <DxSeries
          value-field="salesTy"
          name="Sales This Year"
          :width="3"
          :format="getMoney"
          color="#1bb2f5">

        <DxAggregation
            :enabled="true"
            method="sum"
        />

      </DxSeries>

      <DxSeries
          value-field="salesLy"
          name="Sales Last Year"
          :format="getMoney"
          :width="3"
          color="#f5564a">

        <DxAggregation
            :enabled="true"
            method="sum"
        />
      </DxSeries>

      <DxSeries
          value-field="budgetedSales"
          name="Budgeted Sales 2022"
          :width="3"
          :format="getMoney"
          color="#008000">
        <DxAggregation
            :enabled="true"
            method="sum"
        />

      </DxSeries>

    </DxChart>
  </div>
</template>

<script>
import {
  DxAggregation, DxAggregationInterval,
  DxArgumentAxis,
  DxChart,
  DxCommonSeriesSettings, DxCrosshair, DxExport, DxFormat,
  DxGrid, DxHorizontalLine,
  DxLabel, DxLegend, DxPoint,
  DxSeries, DxTitle, DxValueAxis
} from "devextreme-vue/chart";
import SmallSlimsLoader from "../../../../Homepage/Components/SmallSlimsLoader";
import {DxAnnotation} from "devextreme-vue/chart";
import {DxTooltip} from "devextreme-vue/chart";
import axios from "axios";
import DxSelectBox from "devextreme-vue/select-box";

const items = ['day', 'week', 'month'];

export default {
  name: "WeeklyTrend",
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxArgumentAxis,
    DxGrid,
    DxLabel,
    DxFormat,
    DxValueAxis,
    DxExport,
    DxLegend,
    DxPoint,
    DxCrosshair,
    DxHorizontalLine,
    DxTitle,
    DxTooltip,
    DxAggregation,
    DxAnnotation,
    SmallSlimsLoader,
    DxAggregationInterval,
    DxSelectBox
  },

  data() {
    return {
      data: null,
      dataSource: null,
      isLoading: false,
      items,
      value: items[0],
    }
  },

  created() {
    this.eventHub.$on('filter', this.loadDataWithFilters)
    this.eventHub.$on('clearFilter', this.loadData)
  },

  beforeDestroy() {
    this.eventHub.$off('filter');
    this.eventHub.$off('clearFilter');

  },


  methods: {
    customizeTooltip(pointInfo) {


      let thisYear = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(pointInfo.points[0].value);
      let lastYear = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(pointInfo.points[1].value);
      let budget = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(pointInfo.points[2].value);
      let date = new Date(pointInfo.argumentText).toLocaleDateString("en-US");

      let valueText = String(pointInfo.valueText).split('\n', 3)

      return {
        text: `This Year: ${thisYear}\nLast Year: ${lastYear}\nBudgeted Sales: ${budget}\nDate: ${date}`
      }
    },

    getMoney: function (number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2}).format(number);
    },


    loadDataWithFilters(filterObject) {
      this.isLoading = true;
      const token = this.getTokenSilently();
      const url = this.getApiUrl();

      filterObject.clientId = this.getClientId();

      axios.get(`${url}/api/slims-report/weekly-trend-graph`, {
        headers: {Authorization: `Bearer ${token}`},
        params: filterObject
      }).then(response => {
        this.data = response.data;

        for (let x = 0; x < this.data.length; x++) {
          this.data[x].date = new Date(this.data[x].date)
        }

        this.isLoading = false;
      });
    },

    aggregationChanged(e) {
      if (e.value === 'week' || e.value === 'month') {

        this.eventHub.$emit('isByWeek', true);
      }

      if (e.value === 'day') {

        this.eventHub.$emit('isByWeek', false);
      }
    },

    loadData() {
      this.isLoading = true;

      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      axios.get(`${url}/api/slims-report/weekly-trend-graph`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          clientId: clientId
        }
      }).then(response => {
        this.data = response.data;

        for (let x = 0; x < this.data.length; x++) {
          this.data[x].date = new Date(this.data[x].date)
        }

        this.isLoading = false;
      });
    }
  },

  mounted() {
    // this.loadData();
  }
}
</script>

<style scoped>
.chart-css {
  height: 500px
}

.selectbox-css {
  margin-top: -25px;
}
</style>