<template>
  <DxTabPanel>
    <DxItem
        title="Stores Detail"
        template="store-tab"
    />
    <template #store-tab>
      <promotion-detail-per-store
          :template-data="templateData"
          :promotion-data="promotionData" 
      />
    </template>
    <DxItem
        title="Channel Detail"
        template="channel-tab"
    />
    <template #channel-tab>
      <promotion-detail-per-channel
          :template-data="templateData"
          :promotion-data="promotionData"
      />
    </template>
  </DxTabPanel>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import PromotionDetail from "@/Components/DetailPages/Parts/PromotionDetail/Components/PromotionDetail.vue";
import PromotionDetailPerChannel
  from "@/Components/DetailPages/Parts/PromotionDetail/Components/PromotionDetailPerChannel.vue";
import PromotionDetailPerStore
  from "@/Components/DetailPages/Parts/PromotionDetail/Components/PromotionDetailPerStore.vue";

export default {
  components: {
    PromotionDetail,
    DxTabPanel, DxItem, PromotionDetailPerChannel, PromotionDetailPerStore,
  },
  props: {
    templateData: {
      type: Object,
      default: () => ({}),
    },

    promotionData: {
      type: Object,
      default: () => {
      }
    },
  },
};
</script>
