<template>
  <div>
    <div class="uk-container uk-container-expand uk-margin-small-bottom nav-bar-css">
      <ul class="uk-subnav uk-subnav-pill nav-bar-css nav-bar-text-css">
        <li @click="toggleOverviewPage" :class="{'uk-active': showOverviewCards}"><a href="#" class="nav-bar-text-css">Overview</a>
        </li>
        <li :class="{'uk-active': showYesterdayCompDetail || showRolling7CompDetail || showPeriodCompDetail || showYtdCompDetail || showCompCalcApp}">
          <a href="#" class="nav-bar-text-css">Comp Performance<span data-uk-icon="icon: triangle-down"></span></a>
          <div data-uk-dropdown="mode: hover; delay-hide: 20">
            <ul class="uk-nav uk-dropdown-nav">
              <li class="uk-nav-header">Franchise</li>
              <li @click="compDetailPage('yesterday')"><a href="#">Yesterday</a></li>
              <li @click="compDetailPage('rolling7')"><a href="#">Rolling 7 Day</a></li>
              <li @click="compDetailPage('period')"><a href="#">Period</a></li>
              <li @click="compDetailPage('ytd')"><a href="#">YTD</a></li>

              <li class="uk-nav-divider"></li>
              <li class="uk-nav-header">Corporate</li>
              <li @click="compDetailPage('yesterday_corp')"><a href="#">Yesterday</a></li>
              <li @click="compDetailPage('rolling7_corp')"><a href="#">Rolling 7 Day</a></li>
              <li @click="compDetailPage('period_corp')"><a href="#">Period</a></li>
              <li @click="compDetailPage('ytd_corp')"><a href="#">YTD</a></li>
              <li class="uk-nav-divider"></li>
              <li @click="toggleShowCompCalc()"><a href="#">Contribution To Comp</a></li>
            </ul>
          </div>
        </li>
        <li @click="toggleOverallSalesDetail" :class="{'uk-active': showOverallSalesDetail}"><a href="#"
                                                                                                class="nav-bar-text-css">Overall
          Sales Performance</a></li>
        <li :class="{'uk-active': showPromoDetail || showPromoDetail2}">
          <a href="#">Promotions <span data-uk-icon="icon: triangle-down"></span></a>
          <div v-if="savedPromotions != null" data-uk-dropdown="mode: hover; delay-hide: 20">
            <ul class="uk-nav uk-dropdown-nav">
              <li class="uk-nav-header">Active Promotions</li>
              <li @click="togglePromoDetail(promotion)" v-for="promotion in savedPromotions"><a href="#">
                {{ promotion.promotionName }} </a></li>
              <li v-if="noPromotions"> No Active Promotions On This Page</li>
            </ul>
          </div>
        </li>
        <li @click="toggleShowChannel" :class="{'uk-active': showChannelDetail}"><a href="#" class="nav-bar-text-css">Channel
          Detail</a></li>

        <li :class="{'uk-active': showStoreOverview || showGroupOverview}">
          <a href="#" class="nav-bar-text-css">Detailed Overview <span data-uk-icon="icon: triangle-down"></span></a>
          <div data-uk-dropdown="mode: hover; delay-hide: 20">
            <ul class="uk-nav uk-dropdown-nav">
              <li @click="toggleShowStoreOverview"><a href="#">Store Overview</a></li>
              <li @click="toggleShowGroupOverview"><a href="#">Group Overview</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <div v-show="showOverviewCards" class="iframe-border">
      <div class="uk-grid-medium" data-uk-grid data-uk-height-match>
        <div class="uk-width-1-2@xl">
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div v-show="showCompSalesCard" class="uk-width-auto"><h4>Comp Sales Performance</h4></div>
                <div v-show="!showCompSalesCard" class="uk-width-auto"><h4>Comp Traffic Performance</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span v-show="showCompSalesCard" @click="switchCompCard('Traffic')" class="comp-button-text">Show Traffic</span>
                    <span v-show="!showCompSalesCard" @click="switchCompCard('Sales')" class="comp-button-text">Show Sales</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <comp-sales-card v-show="showCompSalesCard" :yesterday-dates="yesterdayDates"
                                 :rolling7-dates="rolling7Dates" :period-dates="periodDates"
                                 :ytd-dates="ytdDates"></comp-sales-card>
                <comp-traffic-card v-show="!showCompSalesCard" :yesterday-dates="yesterdayDates"
                                   :rolling7-dates="rolling7Dates" :period-dates="periodDates"
                                   :ytd-dates="ytdDates"></comp-traffic-card>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-2@xl">
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div class="uk-width-auto"><h4>Overall Sales Performance</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button data-uk-tooltip="title: View Overall Sales Detail" @click="toggleOverallSalesDetail"
                          class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span>Sales Detail</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <overall-sales-card :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates"
                                    :period-dates="periodDates" :ytd-dates="ytdDates"></overall-sales-card>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-2@xl">
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div class="uk-width-auto"><h4>Recently Opened Stores</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">

                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <recent-stores-card :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates"
                                    :period-dates="periodDates" :ytd-dates="ytdDates"></recent-stores-card>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-expand">
          <div class="uk-width-1-1@xl">
            <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
              <div class="uk-card-header">
                <div class="uk-grid uk-grid-small">
                  <div class="uk-width-auto"><h4>Top 5 Comp Groups <i v-if="rolling7Dates[0] === undefined"
                                                                      :data-uk-tooltip="`title: Loading Date Range...`"
                                                                      class="fa fa-calendar-minus-o"
                                                                      aria-hidden="true"/><i
                      v-if="rolling7Dates[0] !== undefined"
                      :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]"
                      class="fa fa-calendar-minus-o" aria-hidden="true"/></h4></div>
                  <div class="uk-width-expand uk-text-right panel-icons">

                  </div>
                </div>
              </div>
              <div class="uk-card-body">
                <div class="chart-container">
                  <div class="channel-container">
                    <top-stores-card :direction="'desc'"></top-stores-card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1@xl uk-padding-small uk-padding-remove-horizontal">
            <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
              <div class="uk-card-header">
                <div class="uk-grid uk-grid-small">
                  <div class="uk-width-auto"><h4>Bottom 5 Comp Groups <i v-if="rolling7Dates[0] === undefined"
                                                                         :data-uk-tooltip="`title: Loading Date Range...`"
                                                                         class="fa fa-calendar-minus-o"
                                                                         aria-hidden="true"/><i
                      v-if="rolling7Dates[0] !== undefined"
                      :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]"
                      class="fa fa-calendar-minus-o" aria-hidden="true"/></h4></div>
                  <div class="uk-width-expand uk-text-right panel-icons">

                  </div>
                </div>
              </div>
              <div class="uk-card-body">
                <div class="chart-container">
                  <div class="channel-container">
                    <top-stores-card :direction="'asc'"></top-stores-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div v-if="!noPromotions" class="uk-width-1-4@l">
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div v-show="showCurrentPromotions" class="uk-width-auto"><h4>Current Promotions</h4></div>
                <div v-show="!showCurrentPromotions" class="uk-width-auto"><h4>Previous Promotions</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button @click="toggleShowCurrentPromotions" :data-uk-tooltip="promotionsToolTip"
                          class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span v-show="showCurrentPromotions" class="comp-button-text"><i class="fa fa-refresh"
                                                                                     aria-hidden="true"></i></span>
                    <span v-show="!showCurrentPromotions" class="comp-button-text"><i class="fa fa-refresh"
                                                                                      aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="promo-container">
                <promotions-card v-show="showCurrentPromotions"></promotions-card>
                <previous-promotions-card v-show="!showCurrentPromotions"></previous-promotions-card>
              </div>
            </div>
          </div>
        </div>

        <div :class='{"uk-width-1-1@l uk-margin-medium-top": noPromotions, "uk-width-expand@l": !noPromotions}'>
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div class="uk-width-auto"><h4>Comp Sales Channel Summary</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button data-uk-tooltip="title: View Channel Detail" @click="toggleShowChannel"
                          class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span>Channel Sales Detail</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <div class="channel-container">
                  <channel-sales-card :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates"
                                      :period-dates="periodDates" :ytd-dates="ytdDates"></channel-sales-card>
                </div>
              </div>
            </div>
          </div>
        </div>

       

    </div>

    <!------------------------------>
    <!--YESTERDAY COMP DETAIL PAGE-->
    <!------------------------------>
    <yesterday-app v-if="showYesterdayCompDetail" :is-corporate="showCorp"
                   :yesterday-dates="yesterdayDates"></yesterday-app>


    <!------------------------------>
    <!--ROLLING 7 COMP DETAIL PAGE-->
    <!------------------------------>
    <rolling7-app v-if="showRolling7CompDetail" :is-corporate="showCorp" :rolling7-dates="rolling7Dates"></rolling7-app>


    <!------------------------------>
    <!---PERIOD COMP DETAIL PAGE---->
    <!------------------------------>
    <period-detail-app v-if="showPeriodCompDetail" :is-corporate="showCorp"
                       :period-dates="periodDates"></period-detail-app>

    <!------------------------------>
    <!---PROMOTION DETAIL PAGE------>
    <!------------------------------>
    <promotion-detail-app v-if="showPromoDetail" :promotion-data="promoObject"></promotion-detail-app>
    <promotion-detail-app2 v-if="showPromoDetail2" :promotion-data="promoObject2"></promotion-detail-app2>

    <!------------------------------>
    <!---------YTD DETAIL PAGE------>
    <!------------------------------>
    <ytd-detail-app v-if="showYtdCompDetail" :is-corporate="showCorp" :ytd-dates="ytdDates"></ytd-detail-app>


    <!------------------------------>
    <!-----OVERALL DETAIL PAGE------>
    <!------------------------------>
    <overall-sales-detail v-if="showOverallSalesDetail" :yesterday-dates="yesterdayDates"
                          :rolling7-dates="rolling7Dates" :period-dates="periodDates"
                          :ytd-dates="ytdDates"></overall-sales-detail>

    <!------------------------------>
    <!-----CHANNEL DETAIL PAGE------>
    <!------------------------------>
    <channel-detail-app v-if="showChannelDetail"></channel-detail-app>

    <!------------------------------>
    <!-----COMP CALCULATOR PAGE----->
    <!------------------------------>
    <comp-calc-app v-if="showCompCalcApp" :time-range="compCalcTimeRange"></comp-calc-app>

    <!------------------------------>
    <!-----STORE OVERVIEW PAGE----->
    <!------------------------------>
    <store-overview-app v-if="showStoreOverview"></store-overview-app>

    <group-overview-app v-if="showGroupOverview"></group-overview-app>
  </div>
</template>

<script>

import {DxPopup} from 'devextreme-vue/popup';

import axios from "axios";
import CompSalesCard from "@/Components/Homepage/CompSalesCard.vue";
import YesterdayApp from "@/Components/DetailPages/Parts/Yesterday/YesterdayApp.vue";
import Rolling7App from "@/Components/DetailPages/Parts/Rolling7/Rolling7App.vue";
import OverallSalesCard from "@/Components/Homepage/OverallSalesCard.vue";
import PromotionDetailApp from "@/Components/DetailPages/Parts/PromotionDetail/PromotionDetailApp.vue";
import PeriodDetailApp from "@/Components/DetailPages/Parts/Period/PeriodDetailApp.vue";
import YtdDetailApp from "@/Components/DetailPages/Parts/Ytd/YtdDetailApp.vue";
import AddPromotionModal from "@/Components/Homepage/Components/AddPromotionModal.vue";
import PromotionsCard from "@/Components/Homepage/PromotionsCard.vue";
import WordCloud from "@/Components/Homepage/WordCloud.vue";
import ChannelSalesCard from "@/Components/Homepage/ChannelSalesCard.vue";
import OverallSalesDetail from "@/Components/DetailPages/Parts/OverallSalesDetails/OverallSalesDetailApp.vue";
import ChannelDetailApp from "@/Components/DetailPages/Parts/ChannelDetail/ChannelDetailApp.vue";
import CompCalcApp from "@/Components/DetailPages/Parts/ContributionToCompCalculator/CompCalcApp.vue";
import CompTrafficCard from "@/Components/Homepage/CompTrafficCard.vue";
import PromotionDetailApp2 from "@/Components/DetailPages/Parts/PromotionDetail/PromotionDetailApp2.vue";
import PreviousPromotionsCard from "@/Components/Homepage/PreviousPromotionsCard.vue";
import StoreOverviewApp from "@/Components/DetailPages/Parts/StoreOverview/StoreOverviewApp.vue";
import GroupOverviewApp from "@/Components/DetailPages/Parts/StoreOverview/GroupOverviewApp.vue";
import RecentStoresCard from "@/Components/Homepage/RecentStoresCard.vue";
import TopStoresCard from "@/Components/Homepage/TopStoresCard.vue";


export default {
  name: 'App',
  components: {
    CompSalesCard,
    YesterdayApp,
    Rolling7App,
    OverallSalesCard,
    PromotionDetailApp,
    PeriodDetailApp,
    YtdDetailApp,
    AddPromotionModal,
    DxPopup,
    PromotionsCard,
    OverallSalesDetail,
    ChannelSalesCard,
    WordCloud,
    ChannelDetailApp,
    CompCalcApp,
    CompTrafficCard,
    PromotionDetailApp2,
    PreviousPromotionsCard,
    StoreOverviewApp,
    TopStoresCard,
    GroupOverviewApp,
    RecentStoresCard
  },
  data() {
    return {
      showOverviewCards: true,
      showCompDetailPage: false,


      yesterdayDates: [],
      rolling7Dates: [],
      periodDates: [],
      ytdDates: [],

      //Might Use these to show different details but not sure 
      showYesterdayCompDetail: false,
      showRolling7CompDetail: false,
      showPeriodCompDetail: false,
      showYtdCompDetail: false,
      showChannelDetail: false,
      showCompCalcApp: false,
      showStoreOverview: false,
      showGroupOverview: false,
      noPromotions: false,

      showOverallSalesDetail: false,

      compCalcTimeRange: 'YTD',

      showCompSalesCard: true,
      showPromoDetail2: false,

      promotionsToolTip: "Show Previous Promotions",
      showCurrentPromotions: true,
      showCorp: false,
      popupVisible: false,

      promoObject: null,
      promoObject2: null,
      showPromoDetail: false,

      savedPromotions: null,
      savedPromotionsNotActive: null
    };
  },

  mounted() {
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/kpi-dates`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        clientId: clientId
      }
    })
        .then(response => {

          me.yesterdayDates.push(new Date(response.data[0].startDate).toLocaleDateString("en-US"));

          me.rolling7Dates.push(new Date(response.data[1].startDate).toLocaleDateString("en-US"));
          me.rolling7Dates.push(new Date(response.data[1].endDate).toLocaleDateString("en-US"));

          me.periodDates.push(new Date(response.data[2].startDate).toLocaleDateString("en-US"));
          me.periodDates.push(new Date(response.data[2].endDate).toLocaleDateString("en-US"));

          me.ytdDates.push(new Date(response.data[3].startDate).toLocaleDateString("en-US"));
          me.ytdDates.push(new Date(response.data[3].endDate).toLocaleDateString("en-US"));

        })
        .catch(err => {
        })
        .finally(() => {
        });
  },

  created() {
    this.eventHub.$on('compDetailPage', this.compDetailPage);
    this.eventHub.$on('backToOverview', this.toggleOverviewPage);
    this.eventHub.$on('showPromoDetail', this.togglePromoDetail);
    this.eventHub.$on('navbarPromotions', this.savePromotionNames);
    this.eventHub.$on('compCalc', this.toggleShowCompCalc);
    this.eventHub.$on('navbarPromotionsNotActive', this.saveNotActivePromotions);

  },

  beforeDestroy() {
    this.eventHub.$off('compDetailPage');
    this.eventHub.$off('backToOverview');
    this.eventHub.$off('showPromoDetail');
    this.eventHub.$off('navBarPromotions');
    this.eventHub.$off('compCalc');
  },


  methods: {
    toggleEverythingOff() {
      //All pages
      this.showYesterdayCompDetail = false;
      this.showRolling7CompDetail = false;
      this.showPeriodCompDetail = false;
      this.showYtdCompDetail = false;
      this.showPromoDetail = false;
      this.showPromoDetail2 = false;
      this.showOverallSalesDetail = false;
      this.showChannelDetail = false;
      this.showCompCalcApp = false;
      this.showStoreOverview = false;
      this.showGroupOverview = false;
    },

    toggleShowStoreOverview() {
      this.toggleEverythingOff();
      this.showOverviewCards = false;
      this.showStoreOverview = true;
    },


    toggleShowGroupOverview() {
      this.toggleEverythingOff();
      this.showOverviewCards = false;
      this.showGroupOverview = true;
    },

    toggleEverythingOffForPromo() {
      //All pages
      this.showYesterdayCompDetail = false;
      this.showRolling7CompDetail = false;
      this.showPeriodCompDetail = false;
      this.showYtdCompDetail = false;
      this.showOverallSalesDetail = false;
      this.showChannelDetail = false;
      this.showCompCalcApp = false;
      this.showStoreOverview = false;
    },


    toggleShowCompCalc(view) {
      this.toggleEverythingOff();

      if (view !== undefined)
        this.compCalcTimeRange = view;

      this.showOverviewCards = false;
      this.showCompCalcApp = true;
    },

    switchCompCard(card) {
      if (card === 'Sales') {
        this.showCompSalesCard = true;
      }
      if (card === 'Traffic') {
        this.showCompSalesCard = false;
      }
    },


    toggleOverviewPage() {
      //Overview Page

      this.toggleEverythingOff();

      this.showOverviewCards = true;

    },

    toggleShowChannel() {
      this.toggleEverythingOff();

      this.showOverviewCards = false;
      this.showChannelDetail = true;
    },

    toggleShowCurrentPromotions() {
      this.showCurrentPromotions = !this.showCurrentPromotions;

      if (this.showCurrentPromotions === true) {
        this.promotionsToolTip = "Show Previous Promotions";
      } else {
        this.promotionsToolTip = "Show Current Promotions";
      }
    },

    toggleOverallSalesDetail() {
      this.toggleEverythingOff();
      this.showOverviewCards = false;
      this.showOverallSalesDetail = true;
    },

    togglePromoDetail(promoObject) {
      this.toggleEverythingOffForPromo();
      let promo1 = this.showPromoDetail;
      let promo2 = this.showPromoDetail2;

      if (promo1 === false && promo2 === false) {
        this.showPromoDetail2 = false;
        this.showPromoDetail = true;
        this.promoObject = promoObject;
        this.promoObject2 = null;
      }

      if (promo1 === true && promo2 === false) {
        this.showPromoDetail2 = true;
        this.showPromoDetail = false;
        this.promoObject = null;
        this.promoObject2 = promoObject;
      }

      if (promo1 === false && promo2 === true) {
        this.showPromoDetail2 = false;
        this.showPromoDetail = true;
        this.promoObject = promoObject;
        this.promoObject2 = null;
      }

      this.showOverviewCards = false;
    },

    togglePromotionsModal() {
      this.popupVisible = !this.popupVisible;
    },

    savePromotionNames(promotions) {

      if (promotions.length <= 0) {
        this.noPromotions = true;
      }
      this.savedPromotions = promotions;
    },

    saveNotActivePromotions(promotions) {
      this.savedPromotionsNotActive = promotions;
    },

    compDetailPage(view) {

      if (!this.showOverviewCards) {
        this.toggleEverythingOff();
      }

      this.showOverviewCards = false;
      // this.showCompDetailPage = true;

      if (view === 'yesterday') {
        this.showYesterdayCompDetail = true;
        this.showCorp = false;
      }
      if (view === 'rolling7') {
        this.showRolling7CompDetail = true;
        this.showCorp = false;
      }
      if (view === 'period') {
        this.showPeriodCompDetail = true;
        this.showCorp = false;
      }
      if (view === 'ytd') {
        this.showYtdCompDetail = true;
        this.showCorp = false;
      }


      if (view === 'yesterday_corp') {
        this.showYesterdayCompDetail = true;
        this.showCorp = true;
      }
      if (view === 'rolling7_corp') {
        this.showRolling7CompDetail = true;
        this.showCorp = true;
      }
      if (view === 'period_corp') {
        this.showPeriodCompDetail = true;
        this.showCorp = true;
      }
      if (view === 'ytd_corp') {
        this.showYtdCompDetail = true;
        this.showCorp = true;
      }
    }
  },

}
</script>

<style lang="scss">
.color-card {
  background-color: #f7f7f7;
}

.clickable:hover {
  cursor: pointer;
}

.promotion-button-css {
  position: absolute;
  right: 10px;
  top: 15px;
}

.promotion-title {
  top: 20px;
  position: absolute;
  left: 20px;
}

.header-height {
  height: 30px
}

.comp-button {
  height: 30px;
  display: inline-flex !important;
  align-items: center;
}

.promo-container {
  height: 310px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.promo-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.channel-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.iframe-border {
  padding: 3px;
  margin-bottom: 30px;
}

.nav-bar-css {
  margin-right: 10% !important;
  width: 95vw;
  white-space: nowrap;
}

.nav-bar-text-css {
  font-size: 1.2vw;
  white-space: nowrap;
}

.channel-container {
  height: 275px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>