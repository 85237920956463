import Vue from 'vue';
import App from './App.vue';
import '/Assets/js/app.js';
import '/Assets/scss/app.scss';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';
import numeral from "numeral";
import {AuthPlugin} from "../Assets/js/Auth";
// import {SentryInit} from "../../../../Assets/js/Sentry";

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    annotationPlugin,
    zoomPlugin
);

Vue.filter("formatMoney", function (value) {
    return numeral(value).format("$0,0.00");
});

Vue.filter("formatMoneyNoDecimal", function (value) {
    return numeral(value).format("$0,0");
});

Vue.filter("formatMoneyNoCents", function (number) {
    if (number == null)
        return 'Unknown';
    return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
});

Vue.filter("formatNumberMoney", function (value) {
    return numeral(value).format("0,0.0");
});

Vue.filter("formatAverageCheck", function (value) {
    return numeral(value).format("0,0.00");
});

Vue.filter("formatNumberNoDecimal", function (value) {
    return numeral(value).format("0,0");
});

Vue.filter("fixToOneDecimal", function (number) {
    if (number === null)
        return 'Null';

    number = number.toFixed(1);

    return number;
});

Vue.filter("formatDate", function (date) {
    return new Date(date).toLocaleDateString("en-US");
});


Vue.filter("fixToOneDecimalPct", function (number) {
    if (number === null)
        return 'Null';

    number = number.toFixed(1);

    number = number.toString() + "%";
    
    return number;
});

Vue.filter("formatPercentageBy100", function (value) {
    return numeral(value).format('0.0%');
});

Vue.filter("formatPercentageString", function (number) {
    if (number === null)
        return 'Null';

    number = number.toString() + "%";
    
    return number
});

Vue.filter("formatShortNumber", function (num) {
    if (Math.abs(num) > 1000 && Math.abs(num) < 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' k' : Math.sign(num) * Math.abs(num);

    if (Math.abs(num) > 1000000)
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + ' m' : Math.sign(num) * Math.abs(num);
    
    else return num;
});


// SentryInit.init();
const eventHub = new Vue() // Single event hub
Vue.mixin({
    data: function () {
        return {
            eventHub: eventHub
        }
    }
});

Vue.config.productionTip = false
Vue.use(AuthPlugin);

new Vue({
    render: h => h(App)
}).$mount('#app')